import useFetchFromApi from "../../../hooks/useFetchFromApi";
import { datosbyCar } from "../../../apis/endPoints";
import { urlapiimagenes } from "../../../apis/urlApis";
import { tipodato, ubicacionarchivos } from "../../../helpers/constdata";
export default function Caracteristicasopciones({opciones, handleLinkClick, activeOption, indexOption}){
  const { data, error, loading } = useFetchFromApi(`${datosbyCar}/${opciones?.car_codigo}`);

  const opcion = data.data?.filter((registro) => {
    if (registro.dat_atributo !== null) {
      const contieneSlideBotones = registro.dat_atributo
        .toLowerCase()
        .includes("caracteristica-opcion");
      return contieneSlideBotones === true;
    }
  });


   //console.log(opciones)

    return(
        <>

{opcion?.filter(activo => activo.dat_activo === 1) // Filtrar por men_activo igual a 1
    .sort((a, b) => a.dat_ordenamiento - b.dat_ordenamiento) // Ordenar por men_orden
    .map((dato, index) => {
        // Ubicación para guardar la imagen
        let ubicacion;
        if (dato.dat_atributo === 'fondo-slider' && dato.dat_tipo === tipodato[1].texto) {
            ubicacion = ubicacionarchivos[0].carpeta;
        } else if (dato.dat_tipo === tipodato[7].texto) {
            ubicacion = ubicacionarchivos[4].carpeta;
        } else if (dato.dat_tipo === tipodato[8].texto) {
            ubicacion = ubicacionarchivos[5].carpeta;
        } else if (dato.dat_atributo === 'gallery-col' && dato.dat_tipo === tipodato[1].texto) {
            ubicacion = ubicacionarchivos[1].carpeta;
        } else if (dato.dat_atributo !== 'gallery-col' && dato.dat_atributo !== 'fondo-slider' && dato.dat_tipo === tipodato[1].texto) {
            ubicacion = ubicacionarchivos[3].carpeta;
        } else {
            ubicacion = '';
        }

        return (
            <div className="row">
            <div className={`col col-md-${dato.dat_tamano || 12} ${dato.dat_atributo} px-5`} key={index}>

                {dato.dat_tipo === tipodato[0].texto && (
                    <div 
                        dangerouslySetInnerHTML={{ __html: dato.dat_valor }} 
                        className={`${dato.dat_clases}`} 
                        onClick={() => handleLinkClick(indexOption)} 
                    ></div>
                )}
                {dato.dat_tipo === tipodato[1].texto && (
                    <a href={() => false} className={`cursor-active`} onClick={() => handleLinkClick(indexOption)}>
                        <img src={`${urlapiimagenes}${ubicacion}${dato.dat_valor}`} className={` ${dato.dat_clases} img-xs-2`} alt={`imagen-${index}`} />
                    </a>
                )}
                {dato.dat_tipo === tipodato[2].texto && (
                    <a href={() => false} className={`cursor-active ${dato.dat_clases}`} onClick={() => handleLinkClick(indexOption)}>
                        {dato.dat_valor}
                    </a>
                )}
                {dato.dat_tipo === tipodato[5].texto && (
                    <a type="button" className={`btn ${dato.dat_clases}`} onClick={() => handleLinkClick(indexOption)}>
                        {dato.dat_valor}
                    </a>
                )}
                {dato.dat_tipo === tipodato[7].texto && (
                    <div>
                        <a type="button" className={`btn ${dato.dat_clases}`} onClick={() => handleLinkClick(indexOption)}>
                            {dato.dat_valor}
                            {dato.dat_icono && (
                                <img src={`${urlapiimagenes}${ubicacion}${dato.dat_icono}`} alt="Icono" className="icon-right" />
                            )}
                        </a>
                    </div>
                )}
            </div>
            </div>
        );
    })}

        </>
    )
}