import React from "react";
import useFetchFromApi from "../../../hooks/useFetchFromApi";
import { datosbyCar } from "../../../apis/endPoints";
import { urlapiimagenes } from "../../../apis/urlApis";
import { tipodato, ubicacionarchivos } from "../../../helpers/constdata";
export default function Caracteristicasimagenes({opciones}){
    const { data, error, loading } = useFetchFromApi(`${datosbyCar}/${opciones?.car_codigo}`);

  const imagenes = data.data?.filter((registro) => {
    if (registro.dat_atributo !== null) {
      const contieneSlideBotones = registro.dat_atributo
        .toLowerCase()
        .includes("caracteristica-imagen");
      return contieneSlideBotones === true;
    }
  });
  
    return(
        <>
        {imagenes?.filter((imagen, index) => imagen.dat_activo === 1)
  .sort((a, b) => a.dat_ordenamiento - b.dat_ordenamiento)
  .map((dato, index) => {
    let ubicacion;
    if (dato.dat_atributo === 'fondo-slider' && dato.dat_tipo === tipodato[1].texto) {
      ubicacion = ubicacionarchivos[0].carpeta;
    } else if (dato.dat_tipo === 'Botón Compuesto') {
      ubicacion = ubicacionarchivos[4].carpeta;
    } else if (dato.dat_tipo === 'Video') {
      ubicacion = ubicacionarchivos[5].carpeta;
    } else if (dato.dat_atributo === 'gallery-col' && dato.dat_tipo === tipodato[1].texto) {
      ubicacion = ubicacionarchivos[1].carpeta;
    } else if (dato.dat_atributo !== 'gallery-col' && dato.dat_atributo !== 'fondo-slider' && dato.dat_tipo === tipodato[1].texto) {
      ubicacion = ubicacionarchivos[3].carpeta;
    } else {
      ubicacion = '';
    }

    return (
      <React.Fragment key={index}>
        {dato.dat_tipo === tipodato[0].texto && <a href={dato.dat_url ? dato.dat_url : () => false}><div dangerouslySetInnerHTML={{ __html: dato.dat_valor }} className={`${dato.dat_clases}`}/></a>}
          {dato.dat_tipo === tipodato[4].texto && <div className={`${dato.dat_clases}`} dangerouslySetInnerHTML={{ __html: dato.dat_valor }} />}
          {dato.dat_tipo === tipodato[1].texto && <a href={dato.dat_url ? dato.dat_url : () => false}><img src={`${urlapiimagenes}${ubicacion}${dato.dat_valor}`} className={`img-fluid ${dato.dat_clases}`} alt={`imagen-${index}`} /></a>}
          {dato.dat_tipo === tipodato[2].texto && 
          <a href={dato.dat_url ? dato.dat_url : () => false} className={`cursor-active ${dato.dat_clases} `}  >
          {dato.dat_valor}
          
        </a >
          }
          {dato.dat_tipo === tipodato[5].texto && 
          <a type="button" className={`btn ${dato.dat_clases}`} data-toggle="collapse" href={dato.dat_url ? dato.dat_url : '#'} role="button" aria-expanded="false">
          {dato.dat_valor}
          
        </a >
          }
          {dato.dat_tipo === tipodato[7].texto && (
  <div className="col">  {/* Ajusta esta clase según tu layout */}
    <a
      type="button"
      className={`btn ${dato.dat_clases} d-inline-flex justify-content-between align-items-center`}
      data-toggle="collapse"
      href={dato.dat_url ? dato.dat_url : '#'}
      role="button"
      aria-expanded="false"
      
    >
      <span className="flex-grow-1 text-center">{dato.dat_valor}</span>
      {dato.dat_icono && (
        <img
          src={`${urlapiimagenes}${ubicacion}${dato.dat_icono}`}
          alt="Icono"
          className="icon-right ml-2"
        />
      )}
    </a>
  </div>
)}



          {dato.dat_tipo === tipodato[8].texto && (
            <>
            <div className={`${dato.dat_clases}`}>
            <video controls >
              <source src={`${urlapiimagenes}${ubicacion}${dato.dat_valor}`} type="video/mp4" />
              Tu navegador no soporta el elemento de video.
            </video>
            </div>
            </>
          )}

          {dato.dat_tipo === tipodato[9].texto && (
            <div className={`${dato.dat_clases}`}>
              <iframe
                className="embed-responsive-item"
                src={`${dato.dat_valor}`}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          )}
      </React.Fragment>
    );
  })
}
        </>
    )
}