import { useState, useEffect } from 'react';
import { getToken } from '../helpers/getToken';
import { urlapi } from "../apis/urlApis";
import createAxiosInstance from "../apis/axiosConfig";

const {instance, setAuthToken} = createAxiosInstance(urlapi);
const useFetchFromApi = (endpoint) => {

   

    const jwtToken = getToken();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const fetchData = async () => {
        if (!endpoint) {
          setError('Endpoint is not defined');
          setLoading(false);
          return;
      }
        try {
            setAuthToken(jwtToken);
          const response = await instance.get(endpoint);
          
          setData(response.data);
          setLoading(false);
        } catch (error) {
          setError(error.message);
          setLoading(false);
        }
      };
  
      fetchData();
    }, [endpoint, jwtToken]);
    
      return { data, error, loading };
    };

export default useFetchFromApi;
