import React, {useState} from "react";
import useFetchFromApi from "../../../hooks/useFetchFromApi";
import { subopcionesbyidmenu } from "../../../apis/endPoints";
export default function Subopcionesresponsive2({opcion}){
    const { data, error, loading } = useFetchFromApi(`${subopcionesbyidmenu}/${opcion}`);

            const [activeCategory, setActiveCategory] = useState(null); // Categoría activa para títulos
    
            
    const toggleCategory = (category) => {
        setActiveCategory((prev) => (prev === category ? null : category));
    };
    const groupSubopciones = {};
        if (data && data.data) {
            data.data.filter(dato => dato.sop_activo === 1)
            .sort((a, b) => a.sop_orden - b.sop_orden)
            .forEach(subopcion => {
                if (!groupSubopciones[subopcion.sop_categoria]) {
                    groupSubopciones[subopcion.sop_categoria] = [];
                }
                groupSubopciones[subopcion.sop_categoria].push(subopcion);
            });
        }

    //console.log(opcion)
    return(
        <>
        <div className="sub-menu">
                                            {/* Muestra las categorías */}
                                            {Object.keys(groupSubopciones).map((category, index) => (
                                                <div key={index}>
                                                    <a
                                                        className={`dropdown-item ${activeCategory === category ? "font-weight-bold text-decoration-underline" : ""}`}
                                                        onClick={() =>
                                                            toggleCategory(category)
                                                        }
                                                        href={undefined}
                                                    >
                                                        {category}
                                                    </a>
                                                    {activeCategory === category && (
                                                        <div className="sub-sub-menu">
                                                            {/* Muestra los títulos de los elementos */}
                                                            {groupSubopciones[category]
                                        .filter(dato => dato.sop_activo === 1)
                                        .sort((a, b) => a.sop_orden - b.sop_orden)
                                        .map((subopcion, subopcionIndex) => (
                                                                <a
                                                                    href={`${subopcion.sop_url}`}
                                                                    className="dropdown-item"
                                                                    key={subopcionIndex}
                                                                >
                                                                    <div className="" dangerouslySetInnerHTML={{ __html: subopcion.sop_descripcion }} />
                                                                </a>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
        
        </>
    )
}