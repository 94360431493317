import React from "react";
import { subopcionesbyidmenu } from "../../apis/endPoints";
import { urlapiimagenes } from "../../apis/urlApis";
import { ubicacionarchivos } from "../../helpers/constdata";
import useFetchFromApi from "../../hooks/useFetchFromApi";

export default function Subopciones({ valorOpcion, productosRef, setMostrarProductos, handleMouseLeave }) {
    const { data, error, loading } = useFetchFromApi(`${subopcionesbyidmenu}/${valorOpcion}`);

    // Agrupar las subopciones por categoría
    const groupSubopciones = {};
    if (data && data.data) {
        data.data.filter(dato => dato.sop_activo === 1)
        .sort((a, b) => a.sop_orden - b.sop_orden)
        .forEach(subopcion => {
            if (!groupSubopciones[subopcion.sop_categoria]) {
                groupSubopciones[subopcion.sop_categoria] = [];
            }
            groupSubopciones[subopcion.sop_categoria].push(subopcion);
        });
    }

    let ubicacion;
    ubicacion = ubicacionarchivos[6].carpeta;
    return (
        <div className="container-fluid py-5 d-none d-md-block"
            ref={productosRef}
            onMouseEnter={() => setMostrarProductos(true)}
            onMouseLeave={handleMouseLeave}
            style={{
                backgroundColor: '#262626',
                maxHeight: '90vh',
                overflow: 'auto'
            }}>
            {Object.keys(groupSubopciones).map((category, index) => (
  <div key={index}>
    <h2 className="text-white">{category}</h2>
    <div className="row">
      {groupSubopciones[category]
        .filter(dato => dato.sop_activo === 1) // Filtrar por sop_activo igual a 1
        .sort((a, b) => a.sop_orden - b.sop_orden) // Ordenar por sop_orden
        .map((subopcion, subopcionIndex) => (
          <div className="col-md-3 mb-4 text-white text-center" key={subopcionIndex}>
            <div>
              <img
                src={`${urlapiimagenes}${ubicacion}${subopcion.sop_imagen}`}
                className="img-fluid"
                alt="imagen"
              />
              <div className="card-body">
                <div dangerouslySetInnerHTML={{ __html: subopcion.sop_descripcion }} />
                {(subopcion.sop_boton || subopcion.sop_boton2) && (
                  <div>
                    {subopcion.sop_boton && (
                        <div className="mb-2">
                      <a href={`${subopcion.sop_url}`} className="">
                        <img
                          src={`${urlapiimagenes}${ubicacion}${subopcion.sop_boton}`}
                          className="img-fluid"
                          alt="imagen"
                        />
                      </a>
                      </div>
                    )}
                    {subopcion.sop_boton2 && (
                        <div className="">
                      <a href={`${subopcion.sop_url2}`} className="">
                        <img
                          src={`${urlapiimagenes}${ubicacion}${subopcion.sop_boton2}`}
                          className="img-fluid"
                          alt="imagen"
                        />
                      </a>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
    </div>
  </div>
))}

        </div>
    );
}