import { useState } from "react";
import useFetchFromApi from "../../../hooks/useFetchFromApi";
import { caracteristicasbySpa } from "../../../apis/endPoints";
import GaleriaCollageDatos from "./GaleriaCollageDatos";
export default function GaleriaCollage({seccioncollage}){
    const { data, error, loading } = useFetchFromApi(`${caracteristicasbySpa}/${seccioncollage.spa_codigo}`);
    

    const getGridPosition = (item) => {
        let row = 0;
        let col = 0;
    
        switch (item.car_atributo) {
          case 'c1':
            col = 0;
            if (item.car_ubicacion === 1) {
              row = 0;
            } else {
              row = 0;
            }
            break;
          case 'c2':
            col = 1;
            if (item.car_ubicacion === 1) {
              row = 0;
            } else {
              row = 1;
            }
            break;
          case 'c3':
            col = 2;
            row = 0;
            break;
          default:
            break;
        }
    
        return { row, col };
      };
    
    
      return (
        <>
          <section className="py-5 Poppins-regular">
            
            <div className="container-fluid">
          <div className="row no-gutters">
          {data.data
      ?.filter(activo => activo.car_activo === 1)
      .sort((a, b) => a.car_ordenamiento - b.car_ordenamiento)
      .map((item, index) => {
              const { row, col } = getGridPosition(item);
              let colClasses = 'col-md-4';
              let imageClasses = 'w-100 h-100 object-cover';
    
              if (item.car_ubicacion === '2') {
                colClasses = 'col-md-8';
              }
    
    
              return (
                <div
                  key={index}
                  className={`${colClasses} mb-0 gallery-item`}
                  style={{
                    gridRow: `${row + 1} / span ${item.car_ubicacion}`,
                    gridColumn: `${col + 1} / span ${item.car_ubicacion}`
                  }}
                >
                    <div className="m-1 image-container">
                 <GaleriaCollageDatos item={item} imageClasses={imageClasses}/>
                </div>
                </div>
              );
            })}
          </div>
        </div>
          </section>
            </>
        )
    }