import { urlapiimagenes } from "../../../apis/urlApis"
import Datoscaracteristicas from "../../pagina/Datoscaracteristica"
import Carouseldatos from "./Carouseldatos"
export default function Carouselcaracteristicas({grupo}){
    
    return(
        <>
        
        <div className="">
        {grupo.registros?.filter(activo => activo.car_activo === 1) 
        .sort((a, b) => a.car_ordenamiento - b.car_ordenamiento) 
        .map((dato, index) => (
            <div className="row" key={index}>
                <Carouseldatos datoscar={dato}/>
            </div>
             ))}
            
            
        
            
        </div>
                    
        
        
       
        </>
    )
}