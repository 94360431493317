import React, { useState, useEffect } from "react";
import Carouselcaracteristicas from "./Carouselcaracteristicas";
import { urlapiimagenes } from "../../../apis/urlApis";
import useFetchFromApi from "../../../hooks/useFetchFromApi";
import { datosbyCar } from "../../../apis/endPoints";
import { ubicacionarchivos } from "../../../helpers/constdata";

export default function Carouselslide({ Fondo, objetos, spaindex, menutipo }) {
    const { data, error, loading } = useFetchFromApi(`${datosbyCar}/${Fondo.car_codigo}`);

    const [isMobile, setIsMobile] = useState(false);

    // Detectar si la pantalla es móvil
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Consideramos 768px como el ancho máximo para móviles
        };

        // Escuchar el cambio de tamaño de la ventana
        handleResize();
        window.addEventListener("resize", handleResize);

        // Limpieza al desmontar el componente
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const registrosAgrupados = objetos.reduce((acumulador, registro) => {
        const clave = registro.car_ubicacion + "-" + registro.car_atributo;

        if (!acumulador[clave]) {
            acumulador[clave] = {
                ubicacion: registro.car_ubicacion,
                atributo: registro.car_atributo,
                registros: [registro],
            };
        } else {
            acumulador[clave].registros.push(registro);
        }

        return acumulador;
    }, {});

    const gruposRegistros = Object.values(registrosAgrupados);
    const ubicacion = ubicacionarchivos[0].carpeta;

    return (
        <>
            {data.data
                ?.filter((activo) => activo.dat_activo === 1) // Filtrar por dat_activo igual a 1
                .sort((a, b) => a.dat_ordenamiento - b.dat_ordenamiento) // Ordenar por dat_ordenamiento
                .map((dato, index) => {
                    const grupoCorrespondiente = gruposRegistros.filter(
                        (grupo) => grupo.ubicacion === (index + 1).toString()
                    );
                    return (
                        
                        <div
                            key={index}
                            className={`carousel-item ${menutipo === 1 && spaindex === 0 ? "padding-top-md" : ""}  ${index === 0 ? "active" : ""}`}
                            >
                            {dato.dat_url ? (
    <a href={dato.dat_url}>
        <img
            className="img-fluid"
            src={`${urlapiimagenes}${ubicacion}${dato.dat_valor}`}
            alt="Slide"
        />
    </a>
) : (
    <img
        className="img-fluid"
        src={`${urlapiimagenes}${ubicacion}${dato.dat_valor}`}
        alt="Slide"
    />
)}
                            {grupoCorrespondiente &&
                                grupoCorrespondiente.map((grupo, grupoIndex) => (
                                    <div
                                        key={grupoIndex}
                                        className={
                                            isMobile
                                                ? "container" // Cambia a container en pantallas móviles
                                                : `carousel-caption ${grupo.atributo}`
                                        }
                                        style={{ position: isMobile ? "static" : "absolute" }}
                                    >
                                        <Carouselcaracteristicas grupo={grupo} />
                                    </div>
                                ))}
                        </div>
                    );
                })}
        </>
    );
}
