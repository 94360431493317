import React, { useEffect, useRef } from "react";
import useFetchFromApi from "../../../hooks/useFetchFromApi";
import { caracteristicasbySpa } from "../../../apis/endPoints";
import Caracteristicasgrid from "./Caracteristicasgrid";

export default function Carouselgrid({ seccioncarougrid }) {
  const { data, error, loading } = useFetchFromApi(`${caracteristicasbySpa}/${seccioncarougrid.spa_codigo}`);
  const carouselRef = useRef(null);
  // Verificamos si data?.data existe antes de procesarlo
  const groupedData = data?.data?.length
    ? data.data.reduce((acc, item) => {
        if (!acc[item.car_ubicacion]) {
          acc[item.car_ubicacion] = [];
        }
        acc[item.car_ubicacion].push(item);
        return acc;
      }, {})
    : {};

  // Aseguramos que groupedData es un objeto antes de usar Object.keys
  const agrupaciones = Object.keys(groupedData || {});


  const nextSlide = () => {
    if (carouselRef.current) {
      const carousel = new window.bootstrap.Carousel(carouselRef.current);
      carousel.next();
    }
  };

  return (
    <>
      <div id="carouselExampleControls" className="carousel slide" data-ride="carousel" ref={carouselRef}>
        <div className="carousel-inner" >
          {agrupaciones.map((ubicacion, index) => (
            <div key={ubicacion} className={`carousel-item ${index === 0 ? "active" : ""}`}  onClick={nextSlide}>
              <div className="container">
                <div className="row">
                  {groupedData[ubicacion].map((item, idx) => (
                    <div className={`col-12 col-md-${Math.trunc(12 / groupedData[ubicacion].length)}`} key={idx}>
                      <Caracteristicasgrid item={item} index={idx} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>

        <a className="carousel-control-prev carousel-grid-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
          <span className="carousel-control-prev-icon carousel-grid-control-prev-icon" aria-hidden="true"></span>
          <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next carousel-grid-control-next" href="#carouselExampleControls" role="button" data-slide="next">
          <span className="carousel-control-next-icon carousel-grid-control-next-icon" aria-hidden="true"></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
    </>
  );
}
