import React from 'react';
import { caracteristicasbySpa } from '../../../apis/endPoints';
import useFetchFromApi from '../../../hooks/useFetchFromApi';
import Datoscaracteristicas from '../../pagina/Datoscaracteristica';

export default function Grid({ secciongrid }) {
  const { data, error, loading } = useFetchFromApi(`${caracteristicasbySpa}/${secciongrid.spa_codigo}`);
  
  // Separar filas y columnas
  const row_col = secciongrid.spa_cantidad_objetos.split(',').map(numero => parseInt(numero.trim()));
  const filas = row_col[0];
  const columnas = row_col[1];

  // Función para generar las filas
  const renderFilas = () => {
    let rows = [];
    for (let i = 0; i < filas; i++) {
      rows.push(
        <div className="row" key={i}>
          {renderColumnas(i)}
        </div>
      );
    }
    return rows;
  };

  // Función para generar las columnas dentro de cada fila
  const renderColumnas = (fila) => {
    let cols = [];
    for (let j = 0; j < columnas; j++) {
      const identificadorCelda = `fila-${fila + 1}-col-${j + 1}`;
      const atributoClase = data?.data
        ?.filter((activo) => activo.car_activo === 1)
        ?.find((dato) => dato.car_ubicacion === identificadorCelda)?.car_atributo || '';

      cols.push(
        <div className={`col-12 col-md ${atributoClase}`} key={j}>
          <div className="celda" id={identificadorCelda}>
            {data.data
              ?.filter((activo) => activo.car_activo === 1)
              .sort((a, b) => a.car_ordenamiento - b.car_ordenamiento)
              .map((dato, index) => {
                // Verificar si el dato tiene la ubicación de la celda
                if (dato.car_ubicacion === identificadorCelda) {
                  return (
                    <div className={`row`} key={index}>
                      <Datoscaracteristicas datoscar={dato} />
                    </div>
                  );
                } else {
                  return null; // devuelve null si no coincide
                }
              })}
          </div>
        </div>
      );
    }
    return cols;
  };

  return <>{renderFilas()}</>;
}
