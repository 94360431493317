import useFetchFromApi from "../../../hooks/useFetchFromApi";
import { menumodelos } from "../../../helpers/constdata"
import { subopcionesbyidmenu } from "../../../apis/endPoints";
import { ubicacionarchivos } from "../../../helpers/constdata";
import { urlapiimagenes } from "../../../apis/urlApis";

export default function Subopciones2({valorOpcion, opcionMarcada, productosRef, handleMouseEnter, handleMouseLeave, navbarHeight}){
    const { data, error, loading } = useFetchFromApi(`${subopcionesbyidmenu}/${valorOpcion}`);

    const groupSubopciones = {};
        if (data && data.data) {
            data.data.filter(dato => dato.sop_activo === 1)
            .sort((a, b) => a.sop_orden - b.sop_orden)
            .forEach(subopcion => {
                if (!groupSubopciones[subopcion.sop_categoria]) {
                    groupSubopciones[subopcion.sop_categoria] = [];
                }
                groupSubopciones[subopcion.sop_categoria].push(subopcion);
            });
        }
    
        let ubicacion;
        ubicacion = ubicacionarchivos[6].carpeta;

    return(
        <>
        <div
                            className="subnavbar position-absolute d-none d-lg-block Poppins-ExtraBold"
                            style={{
                                top: `${navbarHeight}px`,
                                left: 0,
                                right: 0,
                                zIndex: 1000
                            }}
                            onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                            <div className="container-fluid py-5" >
                        {Object.keys(groupSubopciones).map((category, index) => (
                        <div key={index}>
                            <h3 className='mb-3'>{category}</h3>
                            <div className="row mb-4">
                            {groupSubopciones[category].some(subopcion => !subopcion.sop_imagen) ? (
                                <div className="container-fluid mt-4">
                                <div className="row mb-4 justify-content-center">
                                    {groupSubopciones[category]
                                        .filter(dato => dato.sop_activo === 1)
                                        .sort((a, b) => a.sop_orden - b.sop_orden)
                                        .map((subopcion, subopcionIndex) => (
                                            <div className="col-md-2 text-center" key={subopcionIndex}>
                                                <a href={`${subopcion.sop_url}`} className="text-undecorated text-menu"> 
                                                    <div dangerouslySetInnerHTML={{ __html: subopcion.sop_descripcion }} />
                                                </a> 
                                            </div>
                                        ))}
                                </div>
                                </div>
                            ) : (
                                groupSubopciones[category]
                                    .filter(dato => dato.sop_activo === 1)
                                    .sort((a, b) => a.sop_orden - b.sop_orden)
                                    .map((subopcion, subopcionIndex) => (
                                        <div className="col-md-2 text-center" key={subopcionIndex}>
                                            <a href={`${subopcion.sop_url}`} className='text-undecorated text-dark'>
                                                <img src={`${urlapiimagenes}${ubicacion}${subopcion.sop_imagen}`} alt={subopcion.sop_imagen} className="img-fluid mb-2" />
                                                <div dangerouslySetInnerHTML={{ __html: subopcion.sop_descripcion }} />
                                            </a>
                                        </div>
                                    ))
                            )}

                            </div>
                        </div>
                    ))}
                </div>
                        </div>
        </>
    )
}