//Datos de estado de Slices
export const loading = 'loading';
export const succeeded = 'succeeded';
export const failed = 'failed';

export const secretKey  =  '&w+o8*rki(+t2_%^#s&=-op6)^@x@e9samtl$w*g-g58vl=mbt';

//Mensajes de consumo a APIs en Thunks
export const errorcode = 'Código de estado inesperado:';
export const errorapi = 'Error al realizar la solicitud:';

//Mensajes de validación de campos
export const obligatorio = 'Dato obligatorio.';
export const correo = 'Correo invalido';
export const solonumeros = 'Solo se permiten números.';
export const letrasnumeros = 'El campo solo puede contener letras y espacios.';

//Datos de paginación
export const totalPages = 5;
export const sizeOptions = [10, 25, 50];

//Datos de campos
export const states = [
    {
    'valor': '1',
    'texto': 'activo',
},
{
    'valor': '0',
    'texto': 'Inactivo',
}
]
export const defaultactivo = 1;

export const opctipoopcion = [
    {
    'opcion': 'AGRUPAMIENTO',
},
{
    'opcion': 'OPCIONMENU',
},
{
    'opcion': 'SEPARADOR',
}
]

//Datos de Popups
//Botones
export const buttonexecute = 'Ejecutar';
export const buttoncancel = 'Cancelar';
//Titulos
export const newdata = 'Nuevo Registro';
export const updatedata = 'Actualizar Registro';
export const deletedata = 'Borrar Registro';
//Contenidi
export const deleteconfirm = 'Confirmar que desea borrar el registro.';

//TIPO DATO
export const tipodato = [
    {
    'texto': 'Texto',
},
{
    'texto': 'Imagen',
},
{
    'texto': 'Url',
},
{
    'texto': 'Imagenes',
},
{
    'texto': 'Iframe',
},
{
    'texto': 'Botón',
},
{
    'texto': 'Formulario',
},
{
    'texto': 'Botón Compuesto',
},
{
    'texto': 'Video',
},
{
    'texto': 'Link Video',
},
{
    'texto': 'Icono',
}
]

//Ubicacion para archivos
export const ubicacionarchivos = [
    {
    'carpeta': 'Slider/',
},
{
    'carpeta': 'Galeria/',
},
{
    'carpeta': 'Fondos/',
},
{
    'carpeta': 'Datos/',
},
{
    'carpeta': 'Botones/',
},
{
    'carpeta': 'Videos/',
},
{
    'carpeta': 'Menu/',
}
]
export const BaseUrl = 'http://localhost:3000/design/assets/img/';
export const BaseUrllogos = 'http://localhost:3000/design/assets/';
export const logoBaic =  `${BaseUrllogos}logoBAIC.png`;
export const logoSika =  `${BaseUrllogos}SikaMotors_Negro.png`;
export const correo_receptor = 'info.autosbaic@sikamotors.com';
export const email = 'mailto:info.autosbaic@sikamotors.com';
export const llamada = 'tel:+50224202200';
export const num_llamada = '+502 2420-2200';
export const num_whatsapp = '+502 3758-1166';
export const whatsapp = 'https://wa.me/+50237581166';
export const facebook = 'https://www.facebook.com/people/BAIC-Guatemala/61569345028668/';
export const instagram = 'https://www.instagram.com/baic.gt';
export const textoprecio= 'Precio de Introducción:';

export const menumodelos = [
    {
        category: 'OFF-ROAD',
        items: [
            { title: 'BJ30', imgSrc: `${BaseUrl}Modelos/Off-road/BJ30/bj30mini.png`, link: `${process.env.PUBLIC_URL}/modelo/BJ30` },
            //{ title: 'BJ40', imgSrc: `${BaseUrl}Modelos/Off-road/BJ40/bj40plusmini.png`, link: `${process.env.PUBLIC_URL}/modelo/BJ40`  },
        ]
    },
    {
        category: 'SUV',
        items: [
            { title: 'X7', imgSrc: `${BaseUrl}Modelos/SUV/X7/x7newmini.png`, link: `${process.env.PUBLIC_URL}/modelo/X7` },
            { title: 'X55', imgSrc: `${BaseUrl}Modelos/SUV/X55/x55mini.png`, link: `${process.env.PUBLIC_URL}/modelo/X55` },
            { title: 'X35', imgSrc: `${BaseUrl}Modelos/SUV/X35/x35mini.png`, link: `${process.env.PUBLIC_URL}/modelo/X35` },
        ]
    }
];