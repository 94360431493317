import { useEffect, useState } from 'react';
import { getToken } from '../helpers/getToken';
import createAxiosInstance from '../apis/axiosConfig';
import { urlapi } from '../apis/urlApis';
import { registerForm, sendEmail } from '../apis/endPoints';

const {instance, setAuthToken} = createAxiosInstance(urlapi);

const useForm2 = (configuracion, dat_codigo) => {

  const jwtToken = getToken();

  const [formData, setFormData] = useState({});
  const [touchedFields, setTouchedFields] = useState({});
  const [apiResponse, setApiResponse] = useState(null);
  const [apiError, setApiError] = useState(null); 
  

  const handleChange = (e) => {
    const { name, value, type, checked, required } = e.target;

    let isValid = true;
    // Validar el campo de correo electrónico si es de tipo email
  if (type === 'email') {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    isValid = emailRegex.test(value);
  }

  // Validar campos de tipo texto que solo acepten letras y que no estén vacíos
  if (type === 'text') {
    const letterRegex = /^[A-Za-z]+$/;
    isValid = value.trim() !== '' && letterRegex.test(value);
  }

  // Verificar si el campo es requerido
  const isRequired = required;

  // Validar si el campo es requerido y está vacío
  if (isRequired && value.trim() === '') {
    isValid = false;
  }

    setFormData(prevData => {
      if (type === 'checkbox') {
        return { ...prevData, [name]: checked ? [...(prevData[name] || []), value] : (prevData[name] || []).filter(item => item !== value) };
      } else {
        return { ...prevData, [name]: value };
      }
    });
   // Marcar el campo como tocado y validar si es necesario
  // Marcar el campo como tocado y validar si es necesario
  setTouchedFields({ ...touchedFields, [name]: true });
  // Marcar la validez del campo directamente en el estado formData
  setFormData(prevData => ({ ...prevData, [`${name}Valid`]: isValid }));
  };

  const handleSubmit = async (e, recaptchaValue) => {
    e.preventDefault();

    const emailData = {
        subject: configuracion.title,
        receiver: configuracion.email,
        formData: formData,
        recaptchaToken: recaptchaValue
    };

    try {
      setAuthToken(jwtToken);
          const response = await instance.post(sendEmail, emailData);
          if (response.status === 200) {

            const registerData = {
              dfo_datos: JSON.stringify(formData),
              dfo_coddat: dat_codigo
          };
          //console.log(formData)
            const responseform = await instance.post(registerForm, registerData);
            if (responseform.status === 200) {
            setApiResponse(response.data.message);
            setApiError(null);
            }else{
              setApiResponse(response.data.message);
            setApiError(null);
            }
        } else {
            setApiResponse(response.data.message);
            setApiError(null);
        }
    } catch (error) {
        setApiResponse(null);
        setApiError(error.response?.data?.message || 'Error al enviar el correo.');
    }

    resetForm();
};

useEffect(() => {
  if (apiResponse || apiError) {
    const timer = setTimeout(() => {
      setApiResponse(null);
      setApiError(null);
    }, 9000);
    return () => clearTimeout(timer);
  }
}, [apiResponse, apiError]);

const resetForm = () => {
    setFormData({});
    setTouchedFields({});
};

  const renderField = (campo, index) => {
    const isRequired = campo.required ?? false;
  //const isTouched = touchedFields[campo.name] ?? false;
  //const isValid = formData[`${campo.name}Valid`] ?? true;

  //const inputClassName = `form-input ${isTouched ? (isValid ? 'is-valid' : 'is-invalid') : ''}`;
 
     
    if (campo.type === 'select') {
      return (
        <div className="mb-3" key={index}>
          <label htmlFor="modelo" className="form-label">
          {campo.label}
                </label>
          <select
            className={`form-select ${campo.class}`}
            id={campo.name}
            name={campo.name}
            value={formData[campo.name] || ''}
            onChange={handleChange}
            required={campo.required}
            
          >
            <option value="" disabled className='default-option'>{campo.required ? `${campo.label} *` : campo.label}</option>
            {campo.options.map((option, optionIndex) => (
              <option key={optionIndex} value={option}>
                {option}
              </option>
            ))}
          </select>
          <label for="message"></label>
                                <div class="invalid-feedback" data-sb-feedback="message:required">A message is required.</div>
        </div>
      );
    } else if (campo.type === 'textarea') {
      return (
        <div className= "mb-3" key={index}>
          <label htmlFor="comentario" className="form-label">
          {campo.label}
                </label>
          <textarea
            className={`form-input ${campo.class}`}
            id={campo.name}
            name={campo.name}
            value={formData[campo.name] || ''}
            onChange={handleChange}
            rows={campo.rows || 4}
            required={campo.required}
            placeholder={campo.required ? `* ${campo.label}` : campo.label}
            style={{height: '5rem'}}
          />
          <label for="message">{campo.required ? `* ${campo.label}` : campo.label}</label>
          <div class="invalid-feedback" data-sb-feedback={`${campo.name}:required`}>A message is required.</div>
        </div>
      );
    } else if (campo.type === 'radio') {
      return (
        <div className="form-check mb-3" key={index}>
          <label className="form-label">{campo.required && '* '}{campo.label} :&nbsp;</label>
          {campo.options.map((option, optionIndex) => (
            <div key={optionIndex} className="form-check form-check-inline">
              <input
                type="radio"
            className={`form-check-input ${campo.class}`}
                id={`${campo.name}-${optionIndex}`}
                name={campo.name}
                value={option}
                checked={formData[campo.name] === option}
                onChange={handleChange}
                required={campo.required}
              />
              <label className="form-check-label" htmlFor={`${campo.name}-${optionIndex}`}>{option}</label>
            </div>
          ))}
        </div>
      );
    } else if (campo.type === 'checkbox') {
      return (
        <div className="form-check mb-3" key={index}>
          <label className="form-label">{campo.required && '* '}{campo.label}:</label>
          {campo.options.map((option, optionIndex) => (
            <div key={optionIndex} className="form-check">
              <input
                type="checkbox"
            className={`form-check-input ${campo.class}`}
                id={`${campo.name}-${optionIndex}`}
                name={campo.name}
                value={option}
                checked={formData[campo.name]?.includes(option)}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor={`${campo.name}-${optionIndex}`}>{option}</label>
            </div>
          ))}
        </div>
      );
    } else {
      return (
            <div class="mb-3" key={index}>
              <label htmlFor="nombre" className="form-label">
              {campo.label}
                </label>
            <input
              type={campo.type}
            className={`form-control ${campo.class}`}
              id={campo.name}
              name={campo.name}
              value={formData[campo.name] || ''}
              onChange={handleChange}
              required={isRequired}
              aria-describedby="inputGroupPrepend"
              placeholder={campo.required ? `${campo.label} *` : campo.label}
            />
             <label for="name">{campo.required ? `${campo.label} *` : campo.label}</label>
             <div class="invalid-feedback" data-sb-feedback={`${campo.name}:required`}>Este campo es obligatorio.</div>
        </div>
      );
    }
  };


  return {
    formData,
    handleSubmit,
    renderField,
    titleform: configuracion.title,
    button: configuracion.button,
    apiResponse,
    apiError
  };
};

export default useForm2;