import GeneradorRutas from "./GeneradorRutas";
import useFetchFromApi from "../../hooks/useFetchFromApi";
import { dominio } from "../../apis/endPoints";
import Spinner from "../../helpers/Spinner/Spinner";
export default function DataHostname(){
    
   
    const dominioActual = window.location.hostname.replace(/^www\./, '');


    const { data: datadom, error: errordom, loading: loadingdom } = useFetchFromApi(`${dominio}${dominioActual}`);

    if (loadingdom ) return <Spinner />;
    if (errordom ) return <div>Sitio Web en Proceso...</div>;
    return(
        <>
        <GeneradorRutas hostinfo={datadom} />
        </>
    )
}