// fakeTokenGenerator.js
// Función para obtener un carácter aleatorio de un conjunto de caracteres
const obtenerCaracterAleatorio = (conjunto) => {
    return conjunto.charAt(Math.floor(Math.random() * conjunto.length));
  };
  
  // Función para generar un fake-token aleatorio con el formato específico
  export const generarFakeToken = () => {
    const UID = 'bac?9-5/9ae-9c7!a-4d?7a'; // Tu UID
    const caracteresUID = UID.split('-'); // Obtener los conjuntos de caracteres de UID
    const caracteresAleatorios = 'abcdefghijklmnopqrstuvwxyz0123456789!?/'; // Caracteres aleatorios permitidos
    let fakeToken = '';
  
    // Insertar conjuntos de caracteres de UID intercalados
    for (let i = 0; i < 28; i++) {
      if (i === 6 || i === 13 || i === 20 || i === 27) {
        // Verifica si el índice es válido antes de acceder al array de caracteresUID
        if (caracteresUID[Math.floor(i / 7)]) {
          fakeToken += caracteresUID[Math.floor(i / 7)] ;
        }
      } else {
        // Insertar un carácter aleatorio
        fakeToken += obtenerCaracterAleatorio(caracteresAleatorios);
      }
    }
  
    // Agregar 6 caracteres aleatorios al final
    for (let i = 0; i < 6; i++) {
      fakeToken += obtenerCaracterAleatorio(caracteresAleatorios);
    }
  
    return fakeToken;
  };