import React, { useState, useEffect, useRef } from "react";
import { caracteristicasbySpa } from "../../apis/endPoints";
import useFetchFromApi from "../../hooks/useFetchFromApi";
import Datoscaracteristicas from "./Datoscaracteristica";
import Spinner from "../../helpers/Spinner/Spinner";


export default function Caracteristicaseccion({seccion}){
  const { data, error, loading } = useFetchFromApi(`${caracteristicasbySpa}/${seccion.spa_codigo}`);


 

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

    return(
        <>
  {data.data?.filter(activo => activo.car_activo === 1) 
        .sort((a, b) => a.car_ordenamiento - b.car_ordenamiento) 
        .map((dato, index) => (
    <div className={`row ${dato.car_atributo}`} key={index} >
      <Datoscaracteristicas datoscar={dato}/>
    
    </div>
  ))}



        </>
    )
}