import React from "react";
import { datosbyCar } from "../../../apis/endPoints";
import useFetchFromApi from "../../../hooks/useFetchFromApi";
import { urlapiimagenes } from "../../../apis/urlApis";
import Tiposdatos from "../../pagina/Tiposdatos";
import Spinner from "../../../helpers/Spinner/Spinner";
export default function Acordeondatos({acordeoncaracteristica}){

  const { data, error, loading } = useFetchFromApi(`${datosbyCar}/${acordeoncaracteristica.car_codigo}`);

  const formularioData = data.data?.find(item => item.dat_tipo === "Formulario");

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

    return(
        <>
         {data.data?.map((dato, index) => (
            
            <div className="card-body">
              <Tiposdatos dato={dato} index={index} formularioData={formularioData}/>
            </div>
          
          ))}
        </>
    )
}