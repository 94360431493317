import React, {useState} from "react";
import { menumodelos } from "../../../helpers/constdata";
import useFetchFromApi from "../../../hooks/useFetchFromApi";
import { opcionesmenu } from "../../../apis/endPoints";
import Subopcionesresponsive2 from "./Subopcionesresponsive2";
export default function Navmenuresponsive({dom}){

    const { data: datamenu, error: errormenu, loading: loadingmenu } = useFetchFromApi(`${opcionesmenu}${dom}`);


      const [activeOption, setActiveOption] = useState(null); // "modelos" o una categoría
        const [activeCategory, setActiveCategory] = useState(null); // Categoría activa para títulos
    
        const toggleOption = (option) => {
            setActiveOption((prev) => (prev === option ? null : option));
            setActiveCategory(null); // Resetea la categoría seleccionada
        };
    
        const toggleCategory = (category) => {
            setActiveCategory((prev) => (prev === category ? null : category));
        };

    return(
        <>
        <div className="mobile-menu">
                    <ul className="navbar-nav">
                        {/* Opción Modelos */}
                        {datamenu.data?.filter(dato => dato.men_activo === 1)
            .sort((a, b) => a.men_orden - b.men_orden)
            .map((opcion, index) => (
                <li className="nav-item" key={index}>
                            <a
                                className="nav-link h5 m-3"
                                onClick={() => toggleOption(`${opcion.men_codigo}`)}
                                href={opcion.men_subopciones === 1 ? undefined : `${opcion.men_url}`}
                            >
                               {opcion.men_valor}
                            </a>
                            {activeOption && opcion.men_subopciones === 1 && (
                                <Subopcionesresponsive2 opcion={activeOption}/>
                            )}
                </li>

            ))}
                        
                       
                        
                    </ul>
                </div>
        
        </>
    )
}