import React, { useState, useRef, useEffect } from "react";
import Subopciones from "./Subopciones";
import Subopcionesresponsive from "./Subopcionesresponsive";
import useFetchFromApi from "../../hooks/useFetchFromApi";
import { opcionesmenu } from "../../apis/endPoints";


export default function Navmenu({dom}) {
    const { data, error, loading } = useFetchFromApi(`${opcionesmenu}${dom}`);

//console.log(data  )
  const [mostrarProductos, setMostrarProductos] = useState(false);
  const [opcionMarcada, setOpcionMarcada] = useState(null);
  const [valorOpcion, setValorOpcion] = useState(null);
  const [trianguloPosicion, setTrianguloPosicion] = useState(0);
  const [opcionExpandida, setOpcionExpandida] = useState(null);
  const productosRef = useRef(null);

  const handleMouseEnter = (index, event, codigo) => {
    setOpcionMarcada(index);
    setValorOpcion(codigo);
    setTrianguloPosicion(event.target.offsetLeft + event.target.offsetWidth / 2);
    setMostrarProductos(true);
  };

  const handleMouseLeave = () => {
    setOpcionMarcada(null);
    setMostrarProductos(false);
  };

  const triangleStyle = {
    width: 0,
    height: 0,
    borderLeft: "20px solid transparent",
    borderRight: "20px solid transparent",
    borderBottom: "20px solid rgba(255, 255, 255, 0.6)",
    position: "absolute",
    bottom: "-4px",
    left: trianguloPosicion + "px",
    transform: "translateX(-50%)",
    display: mostrarProductos ? 'block' : 'none'
  };

  const toggleOpcionExpandida = (index) => {
    setOpcionExpandida(opcionExpandida === index ? null : index);
  };


  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768); // Consideramos 768px como ancho máximo para pantallas pequeñas
    };

    // Escuchar el cambio de tamaño de la ventana
    handleResize();
    window.addEventListener("resize", handleResize);

    // Limpieza al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <>
    <nav className="navbar navbar-expand-md navbar-dark col-md-9 order-md-2">
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav ml-auto">
          {data.data?.filter(dato => dato.men_activo === 1)
            .sort((a, b) => a.men_orden - b.men_orden)
            .map((opcion, index) => (
              <li
                key={index}
                className={`nav-item align-items-center ${opcionMarcada === index ? "active" : ""}`}
                onMouseEnter={
                  opcion.men_subopciones
                    ? (event) => handleMouseEnter(index, event, opcion.men_codigo)
                    : null
                }
                onMouseLeave={
                  opcion.men_subopciones
                    ? handleMouseLeave
                    : null
                }
              >
                <a className="nav-link text-white" href={opcion.men_subopciones === 1 && isSmallScreen ? undefined : `${opcion.men_url}`} onClick={() => toggleOpcionExpandida(index)}>
                  {opcion.men_valor}
                </a>
                {opcionExpandida === index && opcionMarcada === index && <Subopcionesresponsive valorOpcion={valorOpcion} />}

                {opcion.men_subopciones ? (
                  <div className="d-none d-md-block">
                    <div style={triangleStyle}></div>
                  </div>
                ) : null}
              </li>
            ))}
        </ul>
      </div>
       
    </nav>
    <div style={{ position: 'absolute', top: '100%', left: 0, right: 0, zIndex: 1000 }}>
    {mostrarProductos && (
        <Subopciones
            valorOpcion={valorOpcion}
            productosRef={productosRef}
            setMostrarProductos={setMostrarProductos}
            handleMouseLeave={handleMouseLeave}
        />
    )}
</div>
</>
  );
}
