import React, { useState, useEffect, useRef } from "react";
import { datosbyCar } from "../../../apis/endPoints";
import useFetchFromApi from "../../../hooks/useFetchFromApi";
import Datoscaracteristicas from "../../pagina/Datoscaracteristica";
import Slidebotones from "../slide/Slidebotones";
import Caracteristicasopciones from "./Caracteristicasopciones";
import Caracteristicasimagenes from "./Caracteristicasimagenes";


export default function Caracteristicasdatos({ registros }) {

  const [activeOption, setActiveOption] = useState(1);

  const handleLinkClick = (index) => {
    setActiveOption(index);
  };
  
  //console.log(activeOption)
    return (
      <div className="">
        <div className="row">
          <div className="col-12 col-md-4">
          {registros.map((registro, index) => (
            <div className={`row ${registro.car_atributo}`}>
              <Caracteristicasopciones opciones={registro}  handleLinkClick={handleLinkClick} activeOption={activeOption} indexOption={index + 1}/>
            </div>
            ))}
            
          </div>
          <div className="col-12 col-md-7">
            <div
              id="carouselExampleIndicators"
              className="carousel slide"
              data-ride="carousel"
            >
              <div className="carousel-inner">
              {registros.map((registro, index) => (
                <div
                className={`carousel-item ${activeOption === index + 1 ? "active" : ""}`}
                key={index}
              >
                <Caracteristicasimagenes opciones={registro}/>
                </div>
              ))}
              </div>
              
            </div>
          </div>
        </div>
      </div>
    );
  }