import Datoscaracteristicas from "../../pagina/Datoscaracteristica"
export default function Caracteristicasgrid({item, index}){
    return(
        <>
        <div className={`row ${item.car_atributo}`} key={index} >
            <Datoscaracteristicas datoscar={item}/>
                                        
        </div>
        
        </>
    )
}