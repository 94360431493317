import React, { useState, useEffect } from 'react';
import axios from 'axios';
import GeneradorRutas from "./components/routes/GeneradorRutas";
import { generarFakeToken } from '../src/helpers/fakeTokenGenerator';
import { secretKey } from '../src/helpers/constdata';
import { sendCredential } from '../src/apis/endPoints';
import { urlapi } from "../src/apis/urlApis";
import CryptoJS from 'crypto-js';
import DataHostname from './components/routes/DataHostname';
import faviconforthing from "./design/faviconforthing.ico";
import faviconbaic from "./design/faviconbaic.ico";
import favicondefault from "./design/favicon.ico";

const changeFavicon = (favicon) => {
  const link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement("link");
  link.type = "image/x-icon";
  link.rel = "shortcut icon";
  link.href = favicon;
  document.head.appendChild(link);
};

const App = () => {

  const cadena = generarFakeToken();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const hostname = window.location.hostname;
    let favicon;
    let title;
  
    if (hostname.includes("forthing.com.gt")) {
      favicon = faviconforthing;
      title = "Forthing - Sitio Oficial";
    } else if (hostname.includes("baic.gt")) {
      favicon = faviconbaic;
      title = "BAIC - Sitio Oficial";
    } else {
      favicon = favicondefault;
      title = "Sitio Web en proceso";
    }
  
    changeFavicon(favicon);
    document.title = title; // Cambia el título del sitio
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${urlapi}${sendCredential}`, {
          cadena: cadena
        });
        
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(response.data), secretKey).toString();
          localStorage.setItem('encryptedData', encryptedData);
      } catch (error) {
        setError('Error al obtener datos: ' + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [cadena]);

  if (loading) {
    return <div></div>;
  }

  if (error) {
    return <div>
  </div>;
  }

  return (
    <DataHostname />
  );
};

export default App;