import React, {useState, useEffect} from "react";
import Menu from "../menu/Menu";

import Carousel from "../SeccionesEspeciales/carousel/Carousel";
import Acordeon from "../SeccionesEspeciales/acordeon/Acordeon";
import Caracteristicaseccion from "./Caracteristicaseccion";
import Galeria from "../SeccionesEspeciales/galeria/Galeria";
import Collapse from "../SeccionesEspeciales/collapse/Collapse";
import Grid from "../SeccionesEspeciales/grid/Grid";
import Slide from "../SeccionesEspeciales/slide/Slide";
import Caracteristicas from "../SeccionesEspeciales/caracteristicas/Caracteristicas";
import { urlapiimagenes } from "../../apis/urlApis";
import { ubicacionarchivos } from "../../helpers/constdata";
import { seccionesbySpr } from "../../apis/endPoints";
import useFetchFromApi from "../../hooks/useFetchFromApi";
import SlideColores from "../SeccionesEspeciales/slidecolores/SlideColores";
import Menu2 from "../menu/menu2/Menu2";
import Flotante from "../SeccionesEspeciales/flotante/Flotante";
import Carouselgrid from "../SeccionesEspeciales/carouselgrid/Carousegrid";
import Popup from "../SeccionesEspeciales/popup/Popup";
import GaleriaCollage from "../SeccionesEspeciales/galeria/GaleriaCollage";

export default function Seccionpagina({ pagina, spr_codigo, hostinfo }) {
    
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };
  
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
    const { data: dataspa, error: errorspa, loading: loadingspa } = useFetchFromApi(`${seccionesbySpr}/${spr_codigo}`);

    const [floatGlobal, setFloatGlobal] = useState(null);
    const [popupGlobal, setPopupGlobal] = useState(null);

    useEffect(() => {
      if (pagina.pag_inicio === 1) {
        const IsInit = dataspa?.data
          ?.filter((activo) => activo.spa_activo === 1)
          .find((item) => item.spa_atributo === "float-global");
  
        if (IsInit) {
          localStorage.setItem("float-global", JSON.stringify(IsInit.spa_codigo));
          setFloatGlobal(IsInit.spa_codigo);
        } else {
          localStorage.removeItem("float-global");
          setFloatGlobal(null);
        }
      } else {
        const storedValue = JSON.parse(localStorage.getItem("float-global"));
        if (storedValue) {
          setFloatGlobal(storedValue);
        }
      }
    }, [dataspa, pagina.pag_inicio]);

    useEffect(() => {
      if (pagina.pag_inicio === 1) {
        localStorage.removeItem("popupShown");

        const IsInitpopup = dataspa?.data
          ?.filter((activo) => activo.spa_activo === 1)
          .find((item) => item.spa_atributo?.includes("popup-global"));
  
        if (IsInitpopup) {
          localStorage.setItem("popup-global", JSON.stringify(IsInitpopup.spa_codigo));
          setPopupGlobal(IsInitpopup);
        } else {
          localStorage.removeItem("popup-global");
          setPopupGlobal(null);
        }
      } else {
        const storedValue = JSON.parse(localStorage.getItem("popup-global"));
        if (storedValue) {
          setPopupGlobal(storedValue);
        }
      }
    }, [dataspa, pagina.pag_inicio]);

    return (
        <>
            {hostinfo[0]?.dom_diseno_menu === 1 ? (
                  <Menu sociedad={hostinfo[0]?.dom_codsoc} dom={hostinfo[0]?.dom_codigo} />
              ) : hostinfo[0]?.dom_diseno_menu === 2 ? (
                  <Menu2 sociedad={hostinfo[0]?.dom_codsoc} dom={hostinfo[0]?.dom_codigo} />
              ) : null}
            
            {floatGlobal && <Flotante seccionflotante={floatGlobal} />}

            {popupGlobal && <Popup seccionpopup={popupGlobal} />}

            {dataspa &&
                dataspa.data?.filter(activo => activo.spa_activo === 1)
        .sort((a, b) => a.spa_orden - b.spa_orden)
        .map((dato, index) => {
          let componente;
          if (dato.spa_especial === 'carousel') {
            // Remove container-fluid and p-0 classes
            componente = <Carousel seccionslide={dato} index={index} menutipo={hostinfo[0]?.dom_diseno_menu}/>; 
          } else if (dato.spa_especial === 'slide') {
            componente = <div className=""><Slide seccionslide={dato} /></div>;
          } else if (dato.spa_especial === 'slide colores') {
            componente = <div className=""><SlideColores seccioncatacteristicas={dato} /></div>;
          } else if (dato.spa_especial === 'acordeon') {
            componente = <div className="container"><Acordeon seccionacordeon={dato} /></div>;
          } else if (dato.spa_especial === 'caracteristicas') {
            componente = <div className=""><Caracteristicas seccioncatacteristicas={dato} /></div>;
          } else if (dato.spa_especial === 'galeria-new') {
            componente = <div className="container"><Galeria seccionacordeon={dato} /></div>;
          } else if (dato.spa_especial === 'collapse') {
            componente = <div className=""><Collapse seccioncollapse={dato} /></div>;
          } else if (dato.spa_especial === 'collage') {
            componente = <div className=""><GaleriaCollage seccioncollage={dato} /></div>;
          } else if (dato.spa_especial === 'grid') {
            componente = <Grid secciongrid={dato} />;
          } else if (dato.spa_especial === 'flotante' && dato.spa_atributo !== 'float-global') {
            componente = <Flotante seccionflotante={dato.spa_codigo} />;
          } else if (dato.spa_especial === 'carousel grid' && dato.spa_atributo !== 'float-global') {
            componente = <div className="container-fluid"><Carouselgrid seccioncarougrid={dato} /></div>; 
          } else if (dato.spa_atributo !== "float-global" && !dato.spa_atributo?.includes("popup-global")) {
            componente = <div className="container-fluid"><Caracteristicaseccion seccion={dato} /></div>;
          }
          
          return (
            <section
            className={`${dato.spa_atributo}`}
            id={dato.spa_titulo}
            style={{
              ...(index === 0 && dato.spa_especial !== 'carousel' && isMobile && {
                marginTop: '0px'
              }),
              ...(dato.spa_estilo === 'Color' && { 
                backgroundColor: `#${dato.spa_contenido}` 
              }),
              ...(dato.spa_estilo === 'Video' && { 
                position: 'relative' 
              }),
              ...(dato.spa_estilo === 'Link-video' && { 
                position: 'relative' 
              }),
              ...(dato.spa_estilo === 'Mapa' && { 
                position: 'relative' 
              }),
              ...(dato.spa_estilo === 'Imagen' && { 
                backgroundImage: `url('${urlapiimagenes}${ubicacionarchivos[2].carpeta}${dato.spa_contenido}')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
              }),
              ...(dato.spa_estilo === null && { 
                minHeight: null 
              })
            }}
            key={index}
          >
              {dato.spa_estilo === 'Video' && (
                <video autoPlay muted loop id="myVideo" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover' }}>
                  <source src={`${urlapiimagenes}${ubicacionarchivos[5].carpeta}${dato.spa_contenido}`}  type="video/mp4" />
                  Tu navegador no soporta el elemento de video.
                </video>
              )}

              {dato.spa_estilo === 'Link-video' && (
                <div style={{ width: '100%', paddingBottom: '56.25%', height: 0 }}>
                  <iframe
                    src={`${dato.spa_contenido}`}
                    title={`${dato.spa_titulo}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                  ></iframe>
                </div>
              )}

            {dato.spa_estilo === 'Mapa' && (
                <iframe src={`${dato.spa_contenido}`}
                allowfullscreen="" 
                loading="lazy" 
                referrerpolicy="no-referrer-when-downgrade"
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}></iframe>
            )}

              {componente}
            </section>
          );
        })}
    </>
  );
}