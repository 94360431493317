import React, { useEffect, useState, useRef } from "react";
import useFetchFromApi from "../../hooks/useFetchFromApi";
import { subopcionesbyidmenu } from "../../apis/endPoints";
import { urlapiimagenes } from "../../apis/urlApis";

export default function Subopcionesresponsive({valorOpcion}){
    
    const { data, error, loading } = useFetchFromApi(`${subopcionesbyidmenu}/${valorOpcion}`);
    const groupSubopciones = {};
    if (data && data.data) {
        data.data.filter(dato => dato.sop_activo === 1)
        .sort((a, b) => a.sop_orden - b.sop_orden)
        .forEach(subopcion => {
            if (!groupSubopciones[subopcion.sop_categoria]) {
                groupSubopciones[subopcion.sop_categoria] = [];
            }
            groupSubopciones[subopcion.sop_categoria].push(subopcion);
        });
    }
    return(
        <>
        <div className="list-group bg-dark d-md-none" >
                        {Object.keys(groupSubopciones).map((category, index) => (
                            <div key={index} className="text-white">
                                {category}
                                {groupSubopciones[category].filter(dato => dato.sop_activo === 1) // Filtrar por men_activo igual a 1
                    .sort((a, b) => a.sop_orden - b.sop_orden) // Ordenar por men_orden
                    .map((subopcion, subopcionIndex) => (
                              <a href={`${subopcion.sop_url}`} className="list-group-item text-white" style={{ backgroundColor: "#111820" }} key={subopcionIndex}>
                                
                                <img src={`${urlapiimagenes}Menu/${subopcion.sop_imagen}`} class="img-fluid" alt="..."></img>
                                <div className="text-center" dangerouslySetInnerHTML={{ __html: subopcion.sop_descripcion }} />
                              </a>
                              ))}
                              </div>
                          ))}
                        </div>

        </>
    )
}