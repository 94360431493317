import React, {useState} from "react"
import useFetchFromApi from "../../../hooks/useFetchFromApi";
import { caracteristicasbySpa } from "../../../apis/endPoints";
import Flotantedatos from "./Flotantedatos";
export default function Flotante({seccionflotante}){

  const { data, error, loading } = useFetchFromApi(`${caracteristicasbySpa}/${seccionflotante}`);

    


    return(
        <>
        {data.data?.filter(activo => activo.car_activo === 1) 
        .sort((a, b) => a.car_ordenamiento - b.car_ordenamiento) 
        .map((dato, index) => (
        <div className={`${dato.car_atributo}`} key={index}>
        
            <Flotantedatos codigo={dato.car_codigo}/>
        
        </div>
        ))}
        
        </>
    )
}