import React, {useState,useEffect, useRef} from "react";
import { menumodelos, llamada, whatsapp, correo, logoSika, ubicacionarchivos } from "../../../helpers/constdata";
import { urlapiimagenes } from "../../../apis/urlApis";
import useFetchFromApi from "../../../hooks/useFetchFromApi";
import { opcionesmenu } from "../../../apis/endPoints";
import Subopciones2 from "./Subopciones2";
export default function Navmenu2({data, dom}){

    const { data: datamenu, error: errormenu, loading: loadingmenu } = useFetchFromApi(`${opcionesmenu}${dom}`);

    //console.log(`${urlapiimagenes}${data.soc_logo_url}`)
    const [isHovered, setIsHovered] = useState(false);
        const navbarRef = useRef(null);
        const [navbarHeight, setNavbarHeight] = useState(0);
        let hoverTimeout;
    
        const [mostrarProductos, setMostrarProductos] = useState(false);
          const [opcionMarcada, setOpcionMarcada] = useState(null);
          const [valorOpcion, setValorOpcion] = useState(null);
          const [opcionExpandida, setOpcionExpandida] = useState(null);
          const productosRef = useRef(null);

          //console.log(valorOpcion)
          //console.log(opcionMarcada)
          //console.log(productosRef)
    const handleMouseEnter = ({index, event, codigo}) => {
       
        setOpcionMarcada(index);
        setValorOpcion(codigo);
        setMostrarProductos(true);
        clearTimeout(hoverTimeout); // Evita que desaparezca si el mouse vuelve rápidamente
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setOpcionMarcada(null);
    setMostrarProductos(false);
        hoverTimeout = setTimeout(() => setIsHovered(false), 200);
    };

    

    useEffect(() => {
            // Obtiene la altura del navbar cuando el componente a sido renderizado
            if (navbarRef.current) {
                setNavbarHeight(navbarRef.current.offsetHeight);
            }
        }, []);

    return(
        <>
       <nav ref={navbarRef} className={`navbar navbar2 navbar-expand-lg navbar-light fixed-top position-absolute d-none d-lg-flex`} style={{ backgroundColor: isHovered ? "white" : "" }}
       >
            <div className="container-fluid">
                
                    <a className="" href="/">
                        
                         <img
                          src={`${urlapiimagenes}${ubicacionarchivos[6].carpeta}${data.soc_logo_url}`}
                          alt="Imagen"
                          className="img-fluid logo-responsive"
                        />
                    </a>
                    
                    <div className="collapse navbar-collapse justify-content-center">
                        <ul className="navbar-nav">
                        {datamenu.data?.filter(dato => dato.men_activo === 1)
            .sort((a, b) => a.men_orden - b.men_orden)
            .map((opcion, index) => (
                <li key={index} className="nav-item" 
                onMouseEnter={
                    opcion.men_subopciones
                      ? (event) => handleMouseEnter({ index: index, event, codigo: opcion.men_codigo })
                      : null
                }
                  onMouseLeave={
                    opcion.men_subopciones
                      ? handleMouseLeave
                      : null
                  }>
                                <a className="nav-link nav-link2 h5 m-3 ml-5" href={opcion.men_url}>{opcion.men_valor}</a>
                            </li>
            ))}
                            
                        </ul>
                    </div>
                    
                        <img src={`${urlapiimagenes}${ubicacionarchivos[6].carpeta}${data.soc_logo_url2}`} alt="Logo" className='img-fluid' style={{ height: "40px" }}/>
                    
                </div>
            </nav>

            {isHovered && (
                <Subopciones2 
                valorOpcion={valorOpcion}
                opcionMarcada={opcionMarcada}
                productosRef={productosRef}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                navbarHeight={navbarHeight}/>
            )}

            
        </>
    )
}