import useFetchFromApi from "../../../hooks/useFetchFromApi";
import { datosbyCar } from "../../../apis/endPoints";
import Spinner from "../../../helpers/Spinner/Spinner";
import { urlapiimagenes } from "../../../apis/urlApis";
import { ubicacionarchivos } from "../../../helpers/constdata";

export default function GaleriaCollageDatos({item, imageClasses}){
    const endpoint = item?.car_codigo ? `${datosbyCar}/${item.car_codigo}` : null;
  const { data, error, loading } = useFetchFromApi(endpoint);


  if (loading) return <p><Spinner /></p>;
  if (error) return <p>Sin datos para mostrar.</p>;

  // Filtrar el primer registro con dat_tipo "Imagen" y dat_activo 1
  const imagenData = data?.data?.find((d) => d.dat_tipo === "Imagen" && d.dat_activo === 1);
  // Filtrar el primer registro con dat_tipo "Texto" y dat_activo 1
  const textoData = data?.data?.find((d) => d.dat_tipo === "Texto" && d.dat_activo === 1);


    return(
        <>
        <a href={item.link} target="_blank" rel="noreferrer">
                  <span className={`image-title2 ${textoData.dat_clases}`} dangerouslySetInnerHTML={{ __html: textoData.dat_valor }}></span>
                    <img src={`${urlapiimagenes}${ubicacionarchivos[3].carpeta}${imagenData.dat_valor}`} alt={imagenData.titulo} className={`gallery-zoom ${imageClasses}`} />
                  </a>
        </>
    )
}