import React, { useState, useEffect, useRef } from "react";
import { caracteristicasbySpa } from "../../../apis/endPoints";
import useFetchFromApi from "../../../hooks/useFetchFromApi";
import Datoscaracteristicas from "../../pagina/Datoscaracteristica";
import Spinner from "../../../helpers/Spinner/Spinner";
export default function Collapse({seccioncollapse}){
    const { data, error, loading } = useFetchFromApi(`${caracteristicasbySpa}/${seccioncollapse.spa_codigo}`);

    const [openCollapse, setOpenCollapse] = useState(null);
  
    const handleCollapseToggle = (collapseId) => {
      setOpenCollapse(openCollapse === collapseId ? null : collapseId);
    };
  
    if (loading) {
      return <Spinner />;
    }
  
    if (error) {
      return <div>Error: {error}</div>;
    }
  
      return(
          <>
    {data.data?.filter(activo => activo.car_activo === 1) // Filtrar por men_activo igual a 1
          .sort((a, b) => a.car_ordenamiento - b.car_ordenamiento) // Ordenar por men_orden
          .map((dato, index) => (
            
            <div key={index} class={`collapse ${index === 0 ? 'show' : ''}`} id={`${dato.car_nombre}`}>
              
      <div className={`row ${dato.car_atributo}`}>
        <Datoscaracteristicas datoscar={dato} handleCollapseToggle={handleCollapseToggle} openCollapse={openCollapse}/>
      </div>
      </div>
    ))}

    
  
  
  
          </>
      )
  }