import React, {useEffect} from "react";
import { caracteristicasbySpa } from "../../../apis/endPoints";
import useFetchFromApi from "../../../hooks/useFetchFromApi";
import Carouselindicators from "./Carouselindicators";
import Carouselslide from "./Carouselslide";
import Spinner from "../../../helpers/Spinner/Spinner";

export default function Carousel({ seccionslide, index, menutipo }) {
    

  useEffect(() => {
    const interval = setInterval(() => {
      const carouselElement = document.querySelector('#slide');
      if (carouselElement) {
        const nextButton = carouselElement.querySelector('.carousel-control-next');
        nextButton && nextButton.click();
      }
    }, 5000); // Cambiar cada 3 segundos

    return () => clearInterval(interval); // Limpiar el intervalo al desmontar
  }, []);

  const { data, error, loading } = useFetchFromApi(`${caracteristicasbySpa}/${seccionslide.spa_codigo}`);

  var Fondo = data.data?.find(registro => registro.car_atributo === 'Fondo' && registro.car_activo === 1);
      
      

      var objetos = data.data?.filter(registro => registro.car_atributo !== 'Fondo' && registro.car_activo === 1);
      
    if (loading) {
      return <Spinner />;
    }
  
    if (error) {
      return <div>Error: {error}</div>;
    }
    return (
      <>
       
    <div id="slide" className="carousel slide" data-ride="carousel" data-interval="3000">
  <Carouselindicators Fondo={Fondo}/>
  <div className="carousel-inner">
  <Carouselslide Fondo={Fondo} objetos={objetos} spaindex={index} menutipo={menutipo}/>
  </div>
  <a className="carousel-control-prev" href="#slide" role="button" data-slide="prev" style={{height: '10%', top: '45%'}}>
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="sr-only">Previous</span>
  </a>
  <a className="carousel-control-next" href="#slide" role="button" data-slide="next" style={{height: '10%', top: '45%'}}>
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="sr-only">Next</span>
  </a>
</div>


        </>
    );
  };

