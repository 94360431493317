import CryptoJS from 'crypto-js';
import { secretKey } from "../constdata";

function decryptData() {
  const encryptedData = localStorage.getItem('encryptedData');
  if (encryptedData) {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return decryptedData;
    } catch (error) {
      console.error('Error al descifrar los datos:', error);
      return null;
    }
  } else {
    return null;
  }
}

export default decryptData;