export const opcionesmenu = 'dominiomenu/bycoddom/';
export const subopcionesbyidmenu = 'subopciones/bycodmen';
export const caracteristicasbySpa = 'caracteristicas/bycodspa';
export const datosbyCar = 'datos/bycodcar';
export const allpaginas = 'allpaginas';
export const sociedadbyPag = '/paginas/bycodsoc';
export const sociedadbyId = '/buscar/sociedades';
export const allproductos = 'allproductos';
export const allsubproductos = 'allsubproductos';
export const productosbyPag = '/productos/bycodpag';
export const subproductosbyPro = '/subproductos/bycodpro';
export const seccionesbySpr = '/seccionespagina/bycodspr';

export const dominio = '/buscar/dom_dominio/';
export const allpaginasbydom = '/paginas/bycoddom/';
export const allproductosbydom = 'productos/bycoddom/';
export const allsubproductosbydom = 'subproductos/bycoddom/';


export const sendCredential = 'website/Generate/Token';
export const sendEmail = 'send-mail';
export const registerForm = 'registrar/datosformularios';

