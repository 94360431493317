import React, {useState} from "react";
import { datosbyCar } from "../../apis/endPoints";
import useFetchFromApi from "../../hooks/useFetchFromApi";
import useForm from "../../hooks/useForm";

import { urlapiimagenes } from "../../apis/urlApis";
import Tiposdatos from "./Tiposdatos";
export default function Nestedcolumns({nestedcol}){
    const formularioData = nestedcol?.find(item => item.dat_tipo === "Formulario");

    // Parsear el JSON en el campo dat_valor solo si existe formularioData
  const configuracionFormulario = formularioData ? JSON.parse(formularioData.dat_valor) : {};

  const { formData, handleSubmit, renderField, titleform, button } = useForm(configuracionFormulario);
    return(
        <>
        
<div className="col">
{/* Mostrar los datos recibidos */}
{nestedcol.filter(activo => activo.dat_activo === 1) // Filtrar por men_activo igual a 1
        .sort((a, b) => a.dat_ordenamiento - b.dat_ordenamiento) // Ordenar por men_orden
        .map((dato, index) => (
        <>
        <div className={`row ${dato.dat_atributo}`}>
        <div className={`col-${dato.dat_tamano ? 12 : 0} col-md-${dato.dat_tamano}`} key={index}>
        
          <Tiposdatos dato={dato} index={index} formularioData={formularioData}/>
</div>
        </div>
        </>
      ))}
</div>

        </>
    )
}