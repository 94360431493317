import axios from 'axios';

// Función para crear una instancia de Axios con el token JWT configurado
const createAxiosInstance = (baseURL) => {
  const instance = axios.create({
    baseURL,
  });

  // Función para establecer el token JWT en el encabezado de las solicitudes
  const setAuthToken = (token) => {
    if (token) {
      instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete instance.defaults.headers.common['Authorization'];
    }
  };

  return { instance, setAuthToken };
};

export default createAxiosInstance;