import useFetchFromApi from "../../../hooks/useFetchFromApi";
import { datosbyCar } from "../../../apis/endPoints";
export default function Carouselindicators({ Fondo }){
    const { data, error, loading } = useFetchFromApi(`${datosbyCar}/${Fondo.car_codigo}`);

    return (
      
      <ul className="carousel-indicators" >
       {data.data?.filter(activo => activo.dat_activo === 1) // Filtrar por men_activo igual a 1
        .sort((a, b) => a.dat_ordenamiento - b.dat_ordenamiento) // Ordenar por men_orden
        .map((dato, index) => (
       <>
    <li key={index} data-target="#slide" data-slide-to={index} className={`${index === 0 ? "active" : ""}`} ></li>
    
    </>
  ))}
  </ul>
        
    );
  };
