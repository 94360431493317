import React, { useState, useEffect, useRef } from "react";
import { caracteristicasbySpa } from "../../../apis/endPoints";
import useFetchFromApi from "../../../hooks/useFetchFromApi";
import Datoscaracteristicas from "../../pagina/Datoscaracteristica";
import Slidebotones from "../slide/Slidebotones";
import Caracteristicasdatos from "./Caracteristicasdatos";

export default function Caracteristicas({ seccioncatacteristicas }) {
  const { data, error, loading } = useFetchFromApi(
    `${caracteristicasbySpa}/${seccioncatacteristicas.spa_codigo}`
  );

  const [slideIndex, setSlideIndex] = useState(0);
  const mostrarDiapositiva = (index) => {
    setSlideIndex(index);
  };

  var botones = data.data?.find((registro) => {
    if (registro.car_atributo !== null) {
      const contieneSlideBotones = registro.car_atributo
        .toLowerCase()
        .includes("caracteristica-botones");
      return contieneSlideBotones === true && registro.car_activo === 1;
    }
  });

  var titulo = data.data?.find((registro) => {
    if (registro.car_atributo !== null) {
      const contieneSlideBotones = registro.car_atributo
        .toLowerCase()
        .includes("caracteristica-titulo");
      return contieneSlideBotones === true && registro.car_activo === 1;
    }
  });

  var objetosAgrupados = data.data?.reduce((grupos, registro) => {
    if (
      registro.car_atributo !== null &&
      registro.car_atributo.toLowerCase().includes("caracteristica-objetos")
    ) {
      const ubicacion = registro.car_ubicacion;
      if (!grupos[ubicacion]) {
        grupos[ubicacion] = [];
      }
      grupos[ubicacion].push(registro);
    }
    return grupos;
  }, {});

  if (!objetosAgrupados) {
    return null; // O puedes renderizar algún mensaje de error o carga mientras objetosAgrupados se carga
  }

  // Convertir objetosAgrupados a un array de objetos { ubicacion, registros }
  const gruposArray = Object.entries(objetosAgrupados).map(
    ([ubicacion, registros]) => ({
      ubicacion,
      registros,
    })
  );

  return (
    <div>
      <div className={`row ${titulo?.car_atributo}`}>
        <Datoscaracteristicas datoscar={titulo} />
      </div>

      <div className={`row ${botones?.car_atributo}`}>
        <Slidebotones botones={botones} mostrarDiapositiva={mostrarDiapositiva} />
      </div>

      {/* Carrusel */}
      <div
        id="carouselExampleControls"
        className="carousel slide"
        data-ride="carousel"
        data-interval="false"
      >
        <div className="carousel-inner">
          {gruposArray.map((grupo, index) => (
            <div
              key={index}
              className={`carousel-item ${
                slideIndex === index ? "active" : ""
              }`}
            >
              <Caracteristicasdatos registros={grupo.registros} />
            </div>
          ))}
        </div>
        
      </div>
    </div>
  );
}
