import React from "react";
import useFetchFromApi from "../../hooks/useFetchFromApi";
import { sociedadbyId } from "../../apis/endPoints";
import Navmenu from "./Navmenu";
import { urlapiimagenes } from "../../apis/urlApis";
import { ubicacionarchivos } from "../../helpers/constdata";

export default function Menu({ sociedad, dom }) {
  const { data, error, loading } = useFetchFromApi(`${sociedadbyId}/${sociedad}`);

 

  return (
    
    <div className="fixed-top topbar-responsive">
      
      <header className="bg-light">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
            <ul className="list-inline mb-1">
              {data.soc_texto_redes && <li className="list-inline-item">{data.soc_texto_redes}</li>}
              {data.soc_instagram && (
                <li className="list-inline-item">
                  <a href={data.soc_instagram} className="text-dark">
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                </li>
              )}
              {data.soc_facebook && (
                <li className="list-inline-item">
                  <a href={data.soc_facebook} className="text-dark">
                    <i className="fa-brands fa-square-facebook"></i>
                  </a>
                </li>
              )}
              {data.soc_linkedin && (
                <li className="list-inline-item">
                  <a href={data.soc_linkedin} className="text-dark">
                    <i className="fa-brands fa-linkedin"></i>
                  </a>
                </li>
              )}
              {data.soc_tiktok && (
                <li className="list-inline-item">
                  <a href={data.soc_tiktok} className="text-dark">
                    <i className="fa-brands fa-tiktok"></i>
                  </a>
                </li>
              )}
              {data.soc_youtube && (
                <li className="list-inline-item">
                  <a href={data.soc_youtube} className="text-dark">
                    <i className="fa-brands fa-youtube"></i>
                  </a>
                </li>
              )}
            </ul>
            </div>
            <div className="col-md-6 text-md-right">
              <p className="mb-0">
              <a href={data.soc_url_dato} style={{ color: 'inherit', textDecoration: 'none' }}>
                {data.soc_icono_menu && <i className={`${data.soc_icono_menu}`}></i>} {data.soc_dato_menu}
              </a>
              </p>
            </div>
          </div>
        </div> 
      </header>

      <div
        className="py-1 text-uppercase"
        style={{ backgroundColor: "#111820" }}
      >
                    <div className="container">
                      <div className="row align-items-center">
                        <div className="col-12 col-md-3 order-md-1 mb-3 mb-md-0">
                        {data.soc_logo_url && (
                        <a href="/">
                        <img
                            src={`${urlapiimagenes}${ubicacionarchivos[6].carpeta}${data.soc_logo_url}`}
                            alt="Imagen"
                            className="img-fluid w-md-100 logo-img"
                          />
                          </a>)}
                        </div>
                        <Navmenu dom={dom}/>
                      </div>
                  </div>
      </div>
    </div>
  );
}
