import decryptData from "./encryption/decryptData";
export function getData(){
  const decryptedData = decryptData();
    //const logindata = localStorage.getItem('totalData');
    
    if (decryptedData) {
        try {
          //const totallogindata = JSON.parse(decryptedData);
          if (decryptedData) {
            return decryptedData;
            
          }
        } catch (error) {
          console.error('Error al analizar los datos de totalData:', error);
        }
      }
    
      return null;
     
}

export function getAuthenticated(){
    const decryptedData = decryptData();
    
    if (decryptedData) {
        try {
          //const totaldata = JSON.parse(decryptedData);
          //console.log(decryptedData);
          if (decryptedData.isSuccess) {
            return decryptedData.isSuccess;
          }
        } catch (error) {
          console.error('Error al analizar los datos de totalData:', error);
        }
      }
    
      return false;
    
}


