import React, { useState, useRef, useEffect } from 'react';
import useFetchFromApi from '../../../hooks/useFetchFromApi';
import { sociedadbyId } from '../../../apis/endPoints';
import { urlapiimagenes } from '../../../apis/urlApis';
import { BaseUrl, correo, llamada, menumodelos, whatsapp, ubicacionarchivos } from '../../../helpers/constdata';
import { logoBaic, logoSika } from '../../../helpers/constdata';
import Navmenu2 from './Navmenu2';
import Subopcionesresponsive2 from './Navmenuresponsive2';

export default function Menu2({ sociedad, dom }) {
  const { data, error, loading } = useFetchFromApi(`${sociedadbyId}/${sociedad}`);

    
    const [isHovered, setIsHovered] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);
    const navbarRef = useRef(null);
    const [navbarHeight, setNavbarHeight] = useState(0);
    let hoverTimeout;
    let hoverTimeout2;

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [activeSubMenu, setActiveSubMenu] = useState(null);

    const handleMouseEnter = () => {
        setIsHovered2(false);

        clearTimeout(hoverTimeout); // Evita que desaparezca si el mouse vuelve rápidamente
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        // Da un pequeño tiempo para que el usuario pueda moverse al submenú
        hoverTimeout = setTimeout(() => setIsHovered(false), 200);
    };

    const handleMouseEnter2 = () => {
        setIsHovered(false);

        clearTimeout(hoverTimeout2); // Evita que desaparezca si el mouse vuelve rápidamente
        setIsHovered2(true);
    };

    const handleMouseLeave2 = () => {
        // Da un pequeño tiempo de gracia para que el usuario pueda moverse al submenú
        hoverTimeout2 = setTimeout(() => setIsHovered2(false), 200);
    };
    
    useEffect(() => {
        // Obtiene la altura del navbar cuando el componente a sido renderizado
        if (navbarRef.current) {
            setNavbarHeight(navbarRef.current.offsetHeight);
        }
    }, []);


    // Abre o cierra el menú responsive
    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
        setActiveSubMenu(null); // Reinicia el submenú activo
    };

   
    

   

    return (
        <>

        <Navmenu2 data={data} dom={dom}/>
            


<nav className="navbar navbar-light fixed-top d-lg-none bg-white pt-3">
    <div className="container d-flex justify-content-between align-items-center">
        {/* Logo alineado a la izquierda */}
        <a className="navbar-brand navbar2-brand" href="/" style={{ flex: '1' }}>
            <img src={`${urlapiimagenes}${ubicacionarchivos[6].carpeta}${data.soc_logo_url}`} alt="Logo" className="img-fluid logo-mobile" />
        </a>
        
        {/* Botón de menú alineado a la derecha */}
        <button className="navbar-toggler navbar-toggler2" type="button" onClick={toggleMobileMenu} style={{ marginLeft: 'auto',
    width: '75px',        // Ajusta según el tamaño deseado
    height: '75px',
    fontSize: '1.5rem' }}>
            <span className="navbar-toggler-icon"></span>
        </button>
    </div>
    
    {isMobileMenuOpen && (
                <Subopcionesresponsive2 dom={dom}/>
            )}
</nav>




        </>
    );
}
