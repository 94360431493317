import React, {useState, useEffect} from "react";
import { Navigate, Route, Routes } from 'react-router-dom';
import Seccionpagina from "../pagina/Seccionpagina";
import { allpaginas, allproductosbydom, allproductos, allsubproductos, allsubproductosbydom } from "../../apis/endPoints";
import useFetchFromApi from "../../hooks/useFetchFromApi";
import Spinner from "../../helpers/Spinner/Spinner";

export default function GeneradorRutas({hostinfo}) {


    const { data: dataprobydom, error: errorprobydom, loading: loadingprobydom } = useFetchFromApi(`${allproductosbydom}${hostinfo[0]?.dom_codigo}`);
    const { data: datasprbydom, error: errorsprbydom, loading: loadingsprbydom } = useFetchFromApi(`${allsubproductosbydom}${hostinfo[0]?.dom_codigo}`);
    


    const getSubProductos = (codPro, datasprbydom) => {
        return datasprbydom.data?.filter(item => item.spr_codpro === codPro && item.spr_activo === 1);
    };

    
    if (loadingprobydom || loadingsprbydom ) return <Spinner />;
    if (errorprobydom || errorsprbydom ) return <div>Error de carga...</div>;

    return (
        <Routes>
            {dataprobydom && dataprobydom.data
                ?.filter(dato => dato.pag_activo === 1 && dato.pro_activo === 1)
                .flatMap((dato, index) => {
                    const subProductos = getSubProductos(dato.pro_codigo, datasprbydom);
                    return subProductos?.map(subProducto => ( 
                        <Route
                            key={`${index}-${dato.pro_codigo}-${subProducto.spr_codigo}`}
                            path={dato.pag_inicio === 1 ? `/` : `/${dato.pag_redireccionamiento}/${dato.pro_nombre}/${subProducto.spr_nombre}`}
                            element={<Seccionpagina pagina={dato} spr_codigo={subProducto.spr_codigo} hostinfo={hostinfo} />}
                        />
                    ));
                })}
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
}
