import React, {useState} from "react"
import useFetchFromApi from "../../../hooks/useFetchFromApi";
import { datosbyCar } from "../../../apis/endPoints";
import Tiposdatos from "../../pagina/Tiposdatos";
export default function Flotantedatos({codigo}){

    const endpoint = codigo ? `${datosbyCar}/${codigo}` : null;
    const { data, error, loading } = useFetchFromApi(endpoint);

    const [hovered, setHovered] = useState(null);

    const handleMouseEnter = (index) => setHovered(index);
    const handleMouseLeave = () => setHovered(null);

    return(
        <>
        {data.data?.filter(activo => activo.car_activo === 1) 
        .sort((a, b) => a.car_ordenamiento - b.car_ordenamiento) 
        .map((btn, index) => (
            <Tiposdatos dato={btn} index={index} />
             ))}
        
        </>
    )
}