import React, { useState } from "react";
import { caracteristicasbySpa } from "../../../apis/endPoints";
import useFetchFromApi from "../../../hooks/useFetchFromApi";
import Acordeondatos from "./Acordeondatos";
import Spinner from "../../../helpers/Spinner/Spinner";


export default function Acordeon({seccionacordeon}){

  const { data, error, loading } = useFetchFromApi(`${caracteristicasbySpa}/${seccionacordeon.spa_codigo}`);

  const [activeIndex, setActiveIndex] = useState(null);

  const handleHeaderClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

    if (loading) {
      return <Spinner />;
    }
  
    if (error) {
      return <div>Error: {error}</div>;
    }
    

    return(
        <>
        {/* Acordeon Section Heading*/}
        <h2 className="page-section-heading text-center mb-5">{seccionacordeon.spa_titulo}</h2>
               
                            

                <div id="accordion">
                {data.data?.filter(activo => activo.car_activo === 1) // Filtrar por men_activo igual a 1
        .sort((a, b) => a.car_ordenamiento - b.car_ordenamiento) // Ordenar por men_orden
        .map((dato, index) => (
          <>
<div className="card border-0">
  <div className="card-header bg-white" onClick={() => handleHeaderClick(index)} aria-expanded={activeIndex === index ? "true" : "false"}>
    <a class={`card-link ${dato.car_atributo}`} data-toggle="collapse" href={`#collapse${dato.car_codigo}`}>
    <span className={activeIndex === index ? 'font-weight-bold' : ''}>
      {dato.car_nombre} 
      
      </span>
    </a>
  </div>
  <div id={`collapse${dato.car_codigo}`} class={`collapse`} data-parent="#accordion">
  <Acordeondatos acordeoncaracteristica={dato}/>
  </div>
</div>

</>
        ))}

</div>

                        
                  
        </>
    )
}