import useForm from "../../hooks/useForm";
import useForm2 from "../../hooks/useForm2";
import { urlapiimagenes } from "../../apis/urlApis";
import ReCAPTCHA from 'react-google-recaptcha';
import { useState } from 'react';
import { tipodato, ubicacionarchivos } from "../../helpers/constdata";

export default function Tiposdatos({ dato, index, formularioData }) {
    const configuracionFormulario = formularioData ? JSON.parse(formularioData.dat_valor) : {};

    const useFormHook = dato.dat_clases === "form-stile-2" ? useForm2 : useForm;

// Llamar al hook seleccionado
const { formData, handleSubmit, renderField, titleform, button, apiResponse, apiError } = useFormHook(configuracionFormulario, dato.dat_codigo);

    const [recaptchaValue, setRecaptchaValue] = useState(null);

    const handleRecaptchaChange = (value) => {
        setRecaptchaValue(value);
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (!recaptchaValue) {
            alert("Por favor completa el reCAPTCHA");
            return;
        }
        handleSubmit(e, recaptchaValue);
    };

    //FUNCIONES PARA ICONO
    const [hovered, setHovered] = useState(null);
    
        const handleMouseEnter = (index) => setHovered(index);
        const handleMouseLeave = () => setHovered(null);

    //Ubicacion para guardar la imagen
    let ubicacion;
    if (dato.dat_atributo === 'fondo-slider' && dato.dat_tipo === tipodato[1].texto) {
    ubicacion = ubicacionarchivos[0].carpeta;
    } else if (dato.dat_tipo === 'Botón Compuesto') {
    ubicacion = ubicacionarchivos[4].carpeta;
    } else if (dato.dat_tipo === 'Video') {
    ubicacion = ubicacionarchivos[5].carpeta;
    } else if (dato.dat_atributo === 'gallery-col' && dato.dat_tipo === tipodato[1].texto) {
    ubicacion = ubicacionarchivos[1].carpeta;
    } else if (dato.dat_atributo !== 'gallery-col' && dato.dat_atributo !== 'fondo-slider' && dato.dat_tipo === tipodato[1].texto) {
      ubicacion = ubicacionarchivos[3].carpeta;
      } else {
    ubicacion = '';
    }

    

    return(
        <>
        {dato.dat_tipo === tipodato[0].texto && (
          <a href={dato.dat_url ? dato.dat_url : () => false}>
            <div
              dangerouslySetInnerHTML={{
                __html: dato.dat_valor.replace(/target="_blank"/g, ""),
              }}
              className={`${dato.dat_clases}`}
            />
          </a>
        )}
        {/*{dato.dat_tipo === tipodato[0].texto && <a href={dato.dat_url ? dato.dat_url : () => false}><div dangerouslySetInnerHTML={{ __html: dato.dat_valor }} className={`${dato.dat_clases}`}/></a>}*/}
          {dato.dat_tipo === tipodato[4].texto && <div className={`${dato.dat_clases}`} dangerouslySetInnerHTML={{ __html: dato.dat_valor }} />}
          {dato.dat_tipo === tipodato[1].texto && <a href={dato.dat_url ? dato.dat_url : () => false}><img src={`${urlapiimagenes}${ubicacion}${dato.dat_valor}`} className={`img-fluid ${dato.dat_clases}`} alt={`imagen-${index}`} /></a>}
          {dato.dat_tipo === tipodato[2].texto && 
          <a href={dato.dat_url ? dato.dat_url : () => false} className={`cursor-active ${dato.dat_clases} `}  >
          {dato.dat_valor}
          
        </a >
          }
          {dato.dat_tipo === tipodato[5].texto && 
          <a type="button" className={`btn ${dato.dat_clases}`} {...(!dato.dat_url && { 'data-toggle': 'collapse' })}  href={dato.dat_url ? dato.dat_url : '#'} role="button" aria-expanded="false">
          {dato.dat_valor}
          
        </a >
          }
          {dato.dat_tipo === tipodato[7].texto && (
  <div className={`col ${dato.dat_atributo}`}>  {/* Ajusta esta clase según tu layout */}
    <a
      type="button"
      className={`btn ${dato.dat_clases} d-inline-flex justify-content-between align-items-center`}
      {...(!dato.dat_url && { 'data-toggle': 'collapse' })} 
      href={dato.dat_url ? dato.dat_url : '#'}
      role="button"
      aria-expanded="false"
      
    >
      <span className="flex-grow-1 text-center">{dato.dat_valor}</span>
      {dato.dat_icono && (
        <img
          src={`${urlapiimagenes}${ubicacion}${dato.dat_icono}`}
          alt="Icono"
          className="icon-right ml-2"
        />
      )}
    </a>
  </div>
)}

{dato.dat_tipo === tipodato[6].texto && (
                <>
                    <h2 className={`${dato.dat_clases === 'form-stile-2' ? 'text-center' : ''}`}>{titleform}</h2>
                    <form onSubmit={handleFormSubmit} key={`form-${index}`} data-sb-form-api-token="API_TOKEN">
                        {configuracionFormulario.fields.map((campo, index) => renderField(campo, index))}
                        <div 
                        className="justify-content-center text-center aling-items-center py-3">
                        <ReCAPTCHA
                            sitekey="6Lffo-UpAAAAAPdivWWy-oTcnVBd6k7WMOAUhWZv"
                            onChange={handleRecaptchaChange}
                        /></div>
                        <button type="submit" className={`btn ${button.class}`}>{button.text}</button>
                    </form>
                    {apiResponse && <div className="text-success">{apiResponse}</div>}
                    {apiError && <div className="text-danger">{apiError}</div>}
                </>
            )}

          {dato.dat_tipo === tipodato[8].texto && (
            <>
            <div className={`${dato.dat_clases}`}>
            <video controls >
              <source src={`${urlapiimagenes}${ubicacion}${dato.dat_valor}`} type="video/mp4" />
              Tu navegador no soporta el elemento de video.
            </video>
            </div>
            </>
          )}

          {dato.dat_tipo === tipodato[9].texto && (
            <div className={`${dato.dat_clases}`}>
              <iframe
                className="embed-responsive-item"
                src={`${dato.dat_valor}`}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          )}

{dato.dat_tipo === tipodato[10].texto && (
  <a href={dato.dat_url}
  className="icon"
  {...(dato.dat_url.startsWith("#") ? {} : { target: "_blank", rel: "noopener noreferrer" })} style={{
    backgroundColor: hovered === index ? `${dato.dat_atributo.split(',')[1]}` : `${dato.dat_atributo.split(',')[0]}`,
    color: hovered === index ? `${dato.dat_atributo.split(',')[0]}` : `${dato.dat_atributo.split(',')[1]}`,
    opacity: hovered !== index ? "0.6" : ""
}}
onMouseEnter={() => handleMouseEnter(index)}
onMouseLeave={handleMouseLeave}
>
<i className={`${dato.dat_valor}`}></i>
</a>
)}
        </>
    )
}