import React, {useState, useEffect} from "react";
import useFetchFromApi from "../../../hooks/useFetchFromApi";
import { datosbyCar } from "../../../apis/endPoints";
import { urlapiimagenes } from "../../../apis/urlApis";
import { tipodato, ubicacionarchivos } from "../../../helpers/constdata";
export default function Caracteristicasimagenes({opciones}){
    const { data, error, loading } = useFetchFromApi(`${datosbyCar}/${opciones?.car_codigo}`);


    const transformarColores = (datos) => {
      
      if (!datos || !datos.data) {
       // console.log("No hay datos:", datos);
        return [];
      }
    
      //console.log("Datos recibidos:", datos.data);
    
      const grupos = datos.data.reduce((grupos, registro) => {
        //console.log("Procesando registro:", registro);
        
        const ordenamiento = registro.dat_ordenamiento;
        
        if (ordenamiento === null || !registro.dat_atributo) {
          //console.log("Registro ignorado - ordenamiento null o sin atributo");
          return grupos;
        }
        
        if (!grupos[ordenamiento]) {
          grupos[ordenamiento] = {
            imgValor: '',
            tipoImg: '',
            clasesImg: '',
            urlImg: '',
            iconoImg: '',
            activoImg: '',
            ordenamientoImg: '',
            color: '',
            logo: '',
            logoclases: '',
            titulo: '',
            fuentetitulo: '',
            fuentedescripcion: '',
            descripcion: ''
          };
        }
        
        const atributo = registro.dat_atributo.toLowerCase();
        //console.log("Atributo procesado:", atributo);
        
        if (atributo.includes("color-imagen")) {
          //console.log("Encontrado color-imagen");
          grupos[ordenamiento].imgValor = registro.dat_valor;
          grupos[ordenamiento].tipoImg = registro.dat_tipo;
          grupos[ordenamiento].clasesImg = registro.dat_clases;
          grupos[ordenamiento].urlImg = registro.dat_url;
          grupos[ordenamiento].iconoImg = registro.dat_icono;
          grupos[ordenamiento].activoImg = registro.dat_activo;
          grupos[ordenamiento].ordenamientoImg = registro.dat_ordenamiento;
        } else if (atributo.includes("color-codigo")) {
          //console.log("Encontrado color-codigo");
          grupos[ordenamiento].color = registro.dat_valor;
        } else if (atributo.includes("color-logo")) {
          //console.log("Encontrado color-codigo");
          grupos[ordenamiento].logo = registro.dat_valor;
          grupos[ordenamiento].logoclases = registro.dat_clases;
        }else if (atributo.includes("color-titulo")) {
          //console.log("Encontrado color-codigo");
          grupos[ordenamiento].titulo = registro.dat_valor;
          grupos[ordenamiento].fuentetitulo = registro.dat_clases;
        }else if (atributo.includes("color-descripcion")) {
          //console.log("Encontrado color-codigo");
          grupos[ordenamiento].descripcion = registro.dat_valor;
          grupos[ordenamiento].fuentedescripcion = registro.dat_clases;
        }
        
        return grupos;
      }, {});
      
      const colores = Object.values(grupos);
      //console.log("Resultado final:", colores);
      
      return colores;
    };
    
    // Uso
    const datoscolores = transformarColores(data);


  


    const [activeIndex, setActiveIndex] = useState(0); // Estado para manejar el índice activo del carrusel

    useEffect(() => {
        if (opciones.length > 0) {
            setActiveIndex(0); // Reinicia el carrusel al primer elemento cuando cambie el array
        }
    }, [opciones]); // Se activa cuando el array 'colores' cambia

    //console.log(activeIndex)

    return ( 
        <>
            {/* Carrusel de imágenes */}
            <div id="carouselColorModelo" className="carousel slide" data-ride="carousel" data-interval="false">
  <div className="carousel-inner">
    {datoscolores.map((color, index) => (
      <div key={index} className={`carousel-item ${index === activeIndex ? 'active' : ''}`}>
        {/* Imagen principal */}
        <div className="d-flex justify-content-center align-items-center">
        <img className="d-block img-fluid img-xl" src={`${urlapiimagenes}${ubicacionarchivos[3].carpeta}${color.imgValor}`} alt={`Color ${index + 1}`} />
           </div>
        {/* Logo y precio */}
        <div className="d-flex justify-content-center align-items-center mt-3 px-3">
          {color.logo &&  (<img
            className={`img-fluid img-logo ${color.logoclases}`}
            src={`${urlapiimagenes}${ubicacionarchivos[3].carpeta}${color.logo}`}
            alt={`Logo ${index + 1}`}
            style={{ maxWidth: '20%', height: 'auto' }}
          />)}
          <div className="text-center mx-5">
  <div className={`${color.fuentetitulo}`} dangerouslySetInnerHTML={{ __html: color.titulo }} />
  
    <div className={`${color.fuentedescripcion}`} dangerouslySetInnerHTML={{ __html: color.descripcion }} />
  
</div>
        </div>
      </div>
    ))}
  </div>
</div>



            {/* Fila de colores */}
            <div className='row justify-content-center'>
            {datoscolores.map((color, index) => {
    const colorStyle = color.color.includes(',') 
        ? { 
          background: `linear-gradient(180deg, ${color.color.split(',')[0]} 50%, ${color.color.split(',')[1]} 50%)`, 
            height: '50px', 
            width: '50px',
            borderRadius: '50%' // Crea círculo perfecto
        } 
        : { 
            backgroundColor: color.color, 
            height: '50px', 
            width: '50px',
            borderRadius: '50px' 
        };

    return (
        <a 
            key={index} 
            className='cursor-active' 
            data-target="#carouselColorModelo" 
            data-slide-to={index} 
            onClick={() => setActiveIndex(index)} // Actualiza el índice activo al hacer clic
        >
            <div className='col-mb-1 m-3'>
                <div className="color-box border" style={colorStyle}></div>
            </div>
        </a>
    );
})}
            </div>
        </>
    );
}
