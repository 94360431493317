import React, { useState, useEffect, useRef } from "react";
import { caracteristicasbySpa } from "../../../apis/endPoints";
import useFetchFromApi from "../../../hooks/useFetchFromApi";
import Datoscaracteristicas from "../../pagina/Datoscaracteristica";
import Slidebotones from "./Slidebotones";
export default function Slide({seccionslide}){
    const { data, error, loading } = useFetchFromApi(`${caracteristicasbySpa}/${seccionslide.spa_codigo}`);

 

    var botones = data.data?.find(registro => {
      if (registro.car_atributo !== null) {
      const contieneSlideBotones = registro.car_atributo.toLowerCase().includes("slide-botones");
      //console.log('Valor de car_atributo:', registro.car_atributo);
      //console.log('¿Contiene slide-botones?', contieneSlideBotones);
      //console.log('Valor de car_activo:', registro.car_activo);
      return contieneSlideBotones === true && registro.car_activo === 1;
      }
    });

    var titulo = data.data?.find(registro => {
      if (registro.car_atributo !== null) {
      const contieneSlideTitulo = registro.car_atributo.toLowerCase().includes("slide-titulo");
      //console.log('Valor de car_atributo:', registro.car_atributo);
      //console.log('¿Contiene slide-botones?', contieneSlideBotones);
      //console.log('Valor de car_activo:', registro.car_activo);
      return contieneSlideTitulo === true && registro.car_activo === 1;
      }
    });

    var objetos = data.data?.filter(registro => {
      // Verificar si registro.car_atributo no es null
      if (registro.car_atributo !== null) {
        const nocontieneSlideBotones = registro.car_atributo.toLowerCase().includes("slide-objetos");
        //console.log('Valor de car_atributo:', registro.car_atributo);
        //console.log('¿Contiene slide-botones?', contieneSlideBotones);
        //console.log('Valor de car_activo:', registro.car_activo);
        return nocontieneSlideBotones === true && registro.car_activo === 1;
      }
    });

    

    

    const [slideIndex, setSlideIndex] = useState(0);

  const mostrarDiapositiva = (index) => {
    setSlideIndex(index);
  };

  return (
    <div>

        <div className={`row ${titulo?.car_atributo}`}>
        <Datoscaracteristicas datoscar={titulo}/>
              
        </div>

      <div className={`row ${botones?.car_atributo}`}>
        <Slidebotones botones={botones} mostrarDiapositiva={mostrarDiapositiva}/>
        </div>

      {/* Carrusel */}
      <div id="carouselExampleControls" className="carousel slide" data-ride="carousel" data-interval="false">
  <div className="carousel-inner">
    {objetos
      ?.filter(activo => activo.car_activo === 1)
      .sort((a, b) => a.car_ordenamiento - b.car_ordenamiento)
      .map((dato, index) => (
        <div key={index} className={`carousel-item ${slideIndex === index ? "active" : ""}`}>
          <div className={`row ${dato.car_atributo}`}>
            <Datoscaracteristicas datoscar={dato}/>
          </div>
        </div>
      ))}
  </div>
</div>
    </div>
  );
}

    
  