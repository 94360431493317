import React from "react";
import { datosbyCar } from "../../../apis/endPoints";
import useFetchFromApi from "../../../hooks/useFetchFromApi";
import { urlapiimagenes } from "../../../apis/urlApis";
import useForm from "../../../hooks/useForm";
import Tiposdatos from "../../pagina/Tiposdatos";
import Spinner from "../../../helpers/Spinner/Spinner";
export default function Carouseldatos({datoscar}){
    
    const { data, error, loading } = useFetchFromApi(`${datosbyCar}/${datoscar.car_codigo}`);

    const formularioData = data.data?.find(item => item.dat_tipo === "Formulario");



    if (loading) {
      return <Spinner />;
    }
  
    
    return(
        <>
        {data.data?.filter(activo => activo.dat_activo === 1) // Filtrar por men_activo igual a 1
        .sort((a, b) => a.dat_ordenamiento - b.dat_ordenamiento) // Ordenar por men_orden
        .map((dato, index) => (
            <div className={`col ${dato.dat_atributo}`} key={index}>
            
            <Tiposdatos dato={dato} index={index} formularioData={formularioData}/>

            </div>
                    
                ))}
        </>
    )
}