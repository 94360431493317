import React, { useState } from "react";
import { datosbyCar } from "../../apis/endPoints";
import useFetchFromApi from "../../hooks/useFetchFromApi";
import useForm from "../../hooks/useForm";
import Spinner from "../../helpers/Spinner/Spinner";

import { urlapiimagenes } from "../../apis/urlApis";

// Importa tus componentes para mostrar los arrays
import Columns from "./Columns";
import Nestedcolumns from "./Nestedcolumns";

export default function Datoscaracteristicas({ datoscar }) {
  const endpoint = datoscar?.car_codigo ? `${datosbyCar}/${datoscar.car_codigo}` : null;
  const { data, error, loading } = useFetchFromApi(endpoint);

  const formularioData = data.data?.find((item) => item.dat_tipo === "Formulario");

  const columns = data?.data?.filter((item) => item.dat_anidado !== 1) || [];
  const nestedcol = data?.data?.filter((item) => item.dat_anidado === 1) || [];

  const configuracionFormulario = formularioData ? JSON.parse(formularioData.dat_valor) : {};

  const { formData, handleSubmit, renderField, titleform, button } = useForm(configuracionFormulario);

  if (loading) {
    return <Spinner />;
  }

  // Determinar cuál array tiene el valor de dat_ordenamiento más pequeño
  let firstArray;
  let secondArray;

  if (columns.length > 0 && nestedcol.length > 0) {
    // Ambos arrays tienen elementos
    firstArray = nestedcol[0].dat_ordenamiento < columns[0].dat_ordenamiento ? nestedcol : columns;
    secondArray = firstArray === nestedcol ? columns : nestedcol;
  } else if (columns.length > 0) {
    // Solo existe el array de columns
    firstArray = columns;
  } else if (nestedcol.length > 0) {
    // Solo existe el array de nestedcol
    firstArray = nestedcol;
  }

  return (
    <>
      {firstArray === nestedcol && <Nestedcolumns nestedcol={nestedcol} />}
      {firstArray === columns && <Columns columns={columns} />}
      {secondArray && secondArray === nestedcol && <Nestedcolumns nestedcol={nestedcol} />}
      {secondArray && secondArray === columns && <Columns columns={columns} />}
    </>
  );
}